.tableTop {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  text-align: left;
  margin: 20px auto;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  font-family: Arial, sans-serif;
}

.dark-mode .tableTop {
  background-color: #2c2c2c;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.tableTop th {
  background-color: #f8f8f8;
  font-weight: 600;
  color: #333333;
  padding: 16px;
  border: none;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.dark-mode .tableTop th {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.tableTop td {
  padding: 16px;
  border: none;
  font-size: 0.95rem;
  vertical-align: middle;
  color: #333333;
  transition: background-color 0.3s ease;
}

.dark-mode .tableTop td {
  color: #d0d0d0;
}

.tableTop tr:not(:last-child) td {
  border-bottom: 1px solid #e0e0e0;
}

.dark-mode .tableTop tr:not(:last-child) td {
  border-bottom-color: #444444;
}

.tableTop tr:nth-child(even) {
  background-color: #fafafa;
}

.dark-mode .tableTop tr:nth-child(even) {
  background-color: #262626;
}

.tableTop tr:hover {
  background-color: #f5f5f5;
}

.dark-mode .tableTop tr:hover {
  background-color: #333333;
}

.tableTop th, .tableTop td {
  position: relative;
}

.tableTop th:after, .tableTop td:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.dark-mode .tableTop th:after, .dark-mode .tableTop td:after {
  background-color: #444444;
}

.tableTop tr:hover td:after {
  transform: scaleX(1);
}

@media screen and (max-width: 768px) {
  .tableTop {
    font-size: 14px;
  }
  .tableTop th {
    font-size: 0.8rem;
    padding: 12px;
  }
  .tableTop td {
    font-size: 0.85rem;
    padding: 12px;
  }
}

.custom-page-number {
  margin: 0 0.25rem;
}

/* Star rating styles */
.table-star-rating {
  color: #ffd700;
  font-size: 1.1rem;
}

.dark-mode .table-star-rating {
  color: #ffeb3b; /* A slightly different yellow for better visibility in dark mode */
}