.workspace-container_ws {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.068) 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 10px 10px;
}

.workspace_ws {
  width: 2000px; /* Ajusta el tamaño según sea necesario */
  height: 2000px; /* Ajusta el tamaño según sea necesario */
  position: relative;
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.096) 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 10px 10px;
}

.menu_ws {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
  padding: 8px;
}

.menu_list_ws {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu_item_ws {
  padding: 8px;
  cursor: pointer;
}

.menu_item_ws:hover {
  background-color: #f0f0f0;
}

.menu_button_ws {
  display: block;
  margin: 8px auto 0;
  padding: 4px 8px;
  cursor: pointer;
}

.item_ws {
  position: absolute;
  border: 1px solid #ccc;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: move;
}

.text_item_ws {
  font-size: 14px;
}

.image_item_ws {
  width: 100px;
  height: 100px;
  background-color: #ddd;
}

.file_item_ws {
  width: 100px;
  height: 100px;
  background-color: #eee;
}

.workspace-title_ws {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8a8a8a;
  font-size: 24px;
  height: 53px;
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 10px; /* Añade espacio debajo del título si es necesario */
}

.trash-can {
  position: fixed;
  bottom: 75px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #8b0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.trash-can:hover {
  transform: scale(1.2);
  background-color: #b22222;
}

.trash-can svg {
  color: white;
  font-size: 24px;
}

.save-button {
  position: fixed;
  bottom: 130px; /* Ajusta esta distancia según lo necesario */
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #090264;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.save-button:hover {
  transform: scale(1.2);
  background-color: #0056b3;
}

.save-button svg {
  color: white;
  font-size: 24px;
}


.add-element-button {
  position: fixed;
  bottom: 255px; /* Ajusta esta distancia según lo necesario */
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #02641e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.add-element-button:hover {
  transform: scale(1.2);
  background-color: #0056b3;
}

.add-element-button svg {
  color: white;
  font-size: 24px;
}

.navigation-arrow {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border: 2px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workspace-container_ws {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.floating-chat-button {
  position: fixed;
  bottom: 200px;
  right: 20px;
  background-color: #4a4a4a;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 70;
}

.floating-chat-button:hover {
  background-color: #333;
}

.meet-button {
  position: fixed;
  bottom: 340px;  /* Ajustar según necesites para la posición vertical */
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4285f4;  /* Color azul de Google Meet */
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
  z-index: 1000;
}

.meet-button:hover {
  background-color: #3367d6;  /* Un azul más oscuro al hover */
  transform: scale(1.1);
}

.meet-button:active {
  transform: scale(0.95);
}