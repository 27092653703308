.form-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 10px auto;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100vh - 60px);
}

.form-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #240f5e;
  margin-bottom: 1.5rem;
  text-align: center;
}


.project-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

}

.project-item {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.project-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-icon {
  font-size: 1.5rem;
}

.project-name {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-color-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--project-color);
}

.delete-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-item:hover .delete-button {
  opacity: 1;
}

.project-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #240f5e;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
  width: max-content;
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-item:hover .project-tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip-arrow {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #240f5e;
}

.project-tooltip h4 {
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.project-tooltip p {
  margin: 0;
  font-size: 0.9rem;
}

.dark-mode .form-step {
  background-color: #1a1a1a;
}

.dark-mode .form-title {
  color: #e0e0e0;
}

.dark-mode .intro-text-container {
  background-color: #2c2c2c;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .intro-text {
  color: #e0e0e0;
}

.dark-mode .project-item {
  background-color: #333;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.dark-mode .project-item:hover {
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.15);
}

.dark-mode .project-name {
  color: #e0e0e0;
}

.dark-mode .delete-button {
  color: #aaa;
}

.dark-mode .project-tooltip {
  background-color: #4a0e4e;
  color: #fff;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .tooltip-arrow {
  border-top-color: #4a0e4e;
}

.dark-mode .project-tooltip h4 {
  color: #fff;
}

.dark-mode .project-tooltip p {
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .form-step {
    padding: 1rem;
  }
  .form-title {
    font-size: 1.4rem;
    margin-top: 2.3rem;
  }
  .project-list-container {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

