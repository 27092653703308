/* Contenedor de opciones */
.optionsContainer {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
}

/* Estilo para cada opción */
.optionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  letter-spacing: 0.5px;
}

.optionLabel input {
  display: none;
}

.selectionCounter {
  font-size: 14px; /* Tamaño de fuente más pequeño para un diseño sutil */
  color: #4B0082; /* Color púrpura suave */
  background-color: #e0ecf8; /* Fondo suave para que contraste pero no abrume */
  padding: 6px 12px; /* Espaciado reducido pero suficiente para la legibilidad */
  border-radius: 50px; /* Bordes redondeados para darle un aspecto suave */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera para que se destaque sin ser intrusivo */
  margin: 0px 0; /* Margen reducido para mantener el diseño compacto */
  display: inline-block; /* Asegura que el contador ocupe solo el espacio necesario */
}

.optionIcon {
  margin-right: 10px;
  color: #4B0082;
  font-size: 20px;
}

/* Estilo para la opción seleccionada */
.selectedOption {
  background-color: #e0e0f8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

/* Contenedor principal del formulario */
.formStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  border-radius: 10px;
}

/* Estilo del título */
.formStep h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
  letter-spacing: 0.3px;
}

/* Estilo del contador de opciones seleccionadas */
.formStep p {
  font-size: 12px;
  color: #555;
  margin-bottom: 16px;
}

/* Estilo del botón */
.formButton {
  background-color: #4B0082;
  color: white;
  border: none;
  padding: 10px 24px;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  letter-spacing: 1px;
}

.formButton:hover {
  background-color: #5c00a3;
  transform: translateY(-2px);
}

.formButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.spinnerAcept {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #4B0082; /* Color púrpura suave */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Animación del spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}