
.fr-finish-register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
/*   background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
 */  font-family: 'Arial', sans-serif;
}

.fr-content-wrapper {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  text-align: center;
  margin-top: -10rem;
}

.fr-content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  
}

.fr-content.fr-visible {
  opacity: 1;
  transform: translateY(0);
}

.fr-title {
  color: #4a4a4a;
  font-size: 2.5em;
  margin-bottom: 10px;
}

.fr-subtitle {
  color: #7d7d7d;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.fr-divider {
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  margin: 20px auto;
}

.fr-message {
  color: #6a6a6a;
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 30px;
}

.fr-start-button {
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1em;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fr-start-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(106, 17, 203, 0.4);
}

@media (max-width: 768px) {
  .fr-content-wrapper {
    padding: 30px;
  }

  .fr-title {
    font-size: 2em;
  }

  .fr-subtitle {
    font-size: 1em;
  }

  .fr-message {
    font-size: 0.9em;
  }
}