.formContainer-loginform {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ffffff, #ffffff);
    padding: 1rem;
    position: relative;
    overflow: hidden;
}

.wave-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
}

.formWrapper-loginform {
    position: relative;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 2rem 1.5rem;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    max-width: 380px;
    width: 100%;
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 1;
    backdrop-filter: blur(10px);
}

.formWrapper-loginform::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: conic-gradient(
        from 0deg,
        transparent 0deg,
        rgba(123, 31, 162, 0.1) 60deg,
        transparent 120deg
    );
    animation: rotateBorder 4s linear infinite;
}

.formWrapper-loginform::after {
    content: '';
    position: absolute;
    inset: 3px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 1.5rem;
    z-index: 0;
}

.formWrapper-loginform > * {
    position: relative;
    z-index: 1;
}

@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.betaBanner-loginform {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #1b134f;
    color: white;
    padding: 0.2rem 0.6rem;
    border-radius: 1rem;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.logoLogin-loginform {
    width: 230px;
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease;
}

.titleLogin-loginform {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: #333;
    letter-spacing: 0.3px;
}

.googleBtn-loginform {
    background-color: #1b134f;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #fff;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    box-shadow: 0 4px 8px rgba(27, 19, 79, 0.12);
}

.googleBtn-loginform:hover {
    background-color: #150d3b;
    box-shadow: 0 6px 12px rgba(27, 19, 79, 0.15);
    transform: translateY(-2px);
}

.btnContent-loginform {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
}

.googleLogo-loginform {
    width: 20px;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.spinner-loginform {
    margin-top: 1.5rem;
    border: 4px solid rgba(27, 19, 79, 0.1);
    border-top: 4px solid #1b134f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.errorMessage-loginform {
    color: #ff4d4f;
    margin-top: 1rem;
    font-weight: 500;
    text-align: center;
    font-size: 0.85rem;
}

.privacyPolicy-loginform {
    margin-top: 1.5rem;
    font-size: 0.8rem;
    color: #666;
    text-align: center;
    line-height: 1.5;
}

.privacyPolicyLink-loginform {
    text-decoration: underline;
    cursor: pointer;
    color: #1b134f;
    transition: color 0.3s ease;
}

.privacyPolicyLink-loginform:hover {
    color: #150d3b;
}

.version-loginform {
    margin-top: 1.5rem;
    font-size: 0.7rem;
    color: #999;
    text-align: center;
}

@media (max-width: 480px) {
    .formWrapper-loginform {
        padding: 2rem 1.5rem;
    }

    .logoLogin-loginform {
        width: 140px;
    }

    .titleLogin-loginform {
        font-size: 1.1rem;
    }

    .googleBtn-loginform {
        padding: 0.75rem 0.9rem;
    }
}

@media (min-width: 768px) {
    .formWrapper-loginform {
        transform: scale(1.02);
    }

    .formWrapper-loginform:hover {
        transform: scale(1.03);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.formWrapper-loginform {
    animation: fadeInUp 0.6s ease-out;
}

.formWrapper-loginform > * {
    animation: fadeInUp 0.6s ease-out;
    animation-fill-mode: both;
}

.formWrapper-loginform > *:nth-child(1) { animation-delay: 0.1s; }
.formWrapper-loginform > *:nth-child(2) { animation-delay: 0.15s; }
.formWrapper-loginform > *:nth-child(3) { animation-delay: 0.2s; }
.formWrapper-loginform > *:nth-child(4) { animation-delay: 0.25s; }

/* Dark mode styles for Login component */
.dark-mode .formContainer-loginform {
    background: linear-gradient(135deg, #1a1a2e, #16213e);
}

.dark-mode .formWrapper-loginform {
    background-color: rgba(30, 30, 30, 0.95);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.dark-mode .formWrapper-loginform::after {
    background: rgba(30, 30, 30, 0.9);
}

.dark-mode .betaBanner-loginform {
    background-color: #3a1d6e;
}

.dark-mode .titleLogin-loginform {
    color: #e0e0e0;
}

.dark-mode .googleBtn-loginform {
    background-color: #3a1d6e;
    color: #fff;
    box-shadow: 0 4px 8px rgba(58, 29, 110, 0.3);
}

.dark-mode .googleBtn-loginform:hover {
    background-color: #4a2d7e;
    box-shadow: 0 6px 12px rgba(58, 29, 110, 0.4);
}

.dark-mode .errorMessage-loginform {
    color: #ff6b6b;
}

.dark-mode .privacyPolicy-loginform {
    color: #b0b0b0;
}

.dark-mode .privacyPolicyLink-loginform {
    color: #9f7aea;
}

.dark-mode .privacyPolicyLink-loginform:hover {
    color: #b794f4;
}

.dark-mode .version-loginform {
    color: #666;
}

/* Adjust the wave background for dark mode */
.dark-mode .wave-background defs linearGradient stop:nth-child(1) {
    stop-color: #2c3e50;
}

.dark-mode .wave-background defs linearGradient stop:nth-child(2) {
    stop-color: #3a1d6e;
}

.dark-mode .wave-background defs linearGradient stop:nth-child(3) {
    stop-color: #16213e;
}

/* Spinner for dark mode */
.dark-mode .spinnerAcept {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
}