:root {
  --main-color: #242134;
  --hover-color: #8c239c;
  --background-color: #363b61;
  --item-background-color: #4a4e7b;
  --tooltip-background: rgba(0, 0, 0, 0.85);
  --text-color: #fff;
  --transition-speed: 0.3s;
  --btn-size: 64px;
  --icon-margin-right: 12px;
  --elevation-shadow: 0 4px 20px rgba(107, 27, 107, 0.3);
  --hover-elevation-shadow: 0 8px 30px rgba(107, 27, 107, 0.4);
}

.floating-btn {
  position: fixed;
  bottom: 6vh;
  right: 3vw;
  z-index: 1000;
}

.main-btn {
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: 50%;
  background-color: var(--main-color);
  color: white;
  border: none;
  font-size: 24px;
  box-shadow: var(--elevation-shadow);
  cursor: pointer;
  transition: all var(--transition-speed) cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.main-btn:hover {
  background-color: var(--hover-color);
  transform: translateY(-4px) scale(1.05);
  box-shadow: var(--hover-elevation-shadow);
}

.main-btn:active {
  transform: translateY(0) scale(0.95);
  box-shadow: var(--elevation-shadow);
}

.main-btn::before {
  content: '+';
  position: absolute;
  font-size: 32px;
  font-weight: 300;
  transition: all var(--transition-speed) ease;
}

.main-btn:hover::before {
  transform: rotate(45deg);
}

.main-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity var(--transition-speed) ease;
}

.main-btn:hover::after {
  opacity: 1;
}

@media (max-width: 768px) {
  :root {
    --btn-size: 56px;
  }

  .floating-btn {
    bottom: 4vh;
    right: 4vw;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(107, 27, 107, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(107, 27, 107, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(107, 27, 107, 0);
  }
}

.main-btn:focus {
  outline: none;
  animation: pulse 1.5s infinite;
}

.main-btn:hover {
  transform: scale(1.1);
  background-color: #363b61;
  box-shadow: var(--hover-elevation-shadow);
}

.main-btn.open {
  animation: rotate-open 0.3s ease forwards;
}

.main-btn.close {
  animation: rotate-close 0.3s ease forwards;
}

@keyframes rotate-open {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes rotate-close {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.menu {
  position: absolute;
  bottom: calc(70px + 10px); /* Despliega un poco más arriba del botón */
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px); /* Mayor distancia para una animación más pronunciada */
  transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.floating-btn.open .menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.floating-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  background-color: var(--background-color);
  color: white;
  font-size: 14px;
  width: 220px; /* Ajustado para una mayor anchura */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  opacity: 0;
  transform: translateY(30px); /* Mayor distancia para una animación más pronunciada */
  transition: background-color var(--transition-speed) ease, opacity var(--transition-speed) ease, transform var(--transition-speed) ease;
  position: relative;
}

.floating-btn.open .floating-item {
  opacity: 1;
  transform: translateY(0);
}

.floating-btn.open .floating-item:nth-child(1) {
  transition-delay: 0.1s;
}

.floating-btn.open .floating-item:nth-child(2) {
  transition-delay: 0.2s;
}

.floating-btn.open .floating-item:nth-child(3) {
  transition-delay: 0.3s;
}

.floating-btn.open .floating-item:nth-child(4) {
  transition-delay: 0.4s;
}

.floating-item:last-child {
  margin-bottom: 0;
}

.floating-item:hover {
  background-color: var(--item-background-color);
  transform: translateX(-10px) scale(1.05);
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.floating-item .icon {
  margin-right: var(--icon-margin-right);
  transition: transform var(--transition-speed) ease, color var(--transition-speed) ease;
}

.floating-item:hover .icon {
  transform: rotate(360deg);
  color: var(--hover-color);
}

.sub-menu {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.floating-btn.open .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.floating-sub-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  margin-bottom: 8px;
  border: none;
  border-radius: 6px;
  background-color: var(--background-color);
  color: white;
  font-size: 12px;
  width: 200px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.floating-sub-item:hover {
  background-color: var(--item-background-color);
  transform: translateX(-10px) scale(1.05);
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.floating-sub-item .icon {
  margin-right: var(--icon-margin-right);
  transition: transform var(--transition-speed) ease, color var(--transition-speed) ease;
}

.floating-sub-item:hover .icon {
  transform: rotate(360deg);
  color: var(--hover-color);
}

.floating-item::after, .floating-sub-item::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  background-color: var(--tooltip-background);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-speed) ease, visibility var(--transition-speed) ease;
  font-size: 12px;
  z-index: 999;
}

.floating-item:hover::after, .floating-sub-item:hover::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .main-btn {
    width: 50px;
    height: 50px;
    font-size: 14px;
  }

  .floating-item, .floating-sub-item {
    padding: 8px 16px;
    font-size: 12px;
    width: 180px;
  }

  .floating-item .icon, .floating-sub-item .icon {
    margin-right: 8px;
  }
}
