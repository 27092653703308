@keyframes popupFadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modalAreas-popupBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: popupFadeIn 0.3s ease;
    z-index: 9999;
}

.modalAreas-popupContainer {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    max-width: 1300px;
    width: 90%;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    z-index: 10000;
}

.modalAreas-popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #3464cc;
    color: white;
    margin: -20px -20px 15px -20px;
    border-radius: 12px 12px 0 0;
}

.modalAreas-popupTitle {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
}

.modalAreas-popupContent {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

.modalAreas-popupInputContainer {
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.modalAreas-popupInputLabel {
    display: block;
    margin-bottom: 6px;
    font-weight: 500;
    color: #2850a7;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.modalAreas-popupInput {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid #e8ecff;
    border-radius: 10px;
    font-size: 15px;
    transition: all 0.3s ease;
    box-sizing: border-box;
    max-width: 100%;
    background-color: #f8f9ff;
    color: #333;
}

.modalAreas-popupInput:hover {
    border-color: #3464cc;
    background-color: #ffffff;
}

.modalAreas-popupInput:focus {
    outline: none;
    border-color: #3464cc;
    background-color: #ffffff;
    box-shadow: 0 0 0 3px rgba(52, 100, 204, 0.1);
}

.modalAreas-popupInput::placeholder {
    color: #a0aec0;
}

.modalAreas-popupInputError {
    border-color: #EF5350;
    background-color: #fff8f8;
}

.modalAreas-popupInputError:focus {
    box-shadow: 0 0 0 3px rgba(239, 83, 80, 0.1);
}

.modalAreas-popupInputHelperText {
    color: #EF5350;
    font-size: 13px;
    margin-top: 6px;
    display: block;
    padding-left: 4px;
}

.modalAreas-characterCount {
    position: absolute;
    right: 8px;
    bottom: -20px;
    font-size: 12px;
    color: #718096;
}

.modalAreas-customizeContainer {
    margin-top: 15px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #eaeaea;
    display: flex;
    gap: 15px;
}

.modalAreas-colorSelection,
.modalAreas-iconSelection {
    background-color: #f8f9ff;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 0;
    border: 1px solid #e8ecff;
    transition: all 0.3s ease;
    flex: 1;
}

.modalAreas-colorSelection:hover,
.modalAreas-iconSelection:hover {
    box-shadow: 0 4px 15px rgba(52, 100, 204, 0.1);
    transform: translateY(-2px);
}

.modalAreas-colorSelection h3,
.modalAreas-iconSelection h3 {
    font-size: 14px;
    font-weight: 600;
    color: #2850a7;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.modalAreas-colorSelection h3::before {
    content: "🎨";
    font-size: 20px;
}

.modalAreas-iconSelection h3::before {
    content: "✨";
    font-size: 20px;
}

.modalAreas-colorOptions,
.modalAreas-iconOptions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    gap: 8px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #eaeaea;
}

.modalAreas-colorCircle {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.2s ease;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.modalAreas-colorCircle:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modalAreas-colorCircle.selected {
    border-color: #3464cc;
    transform: scale(1.1);
}

.modalAreas-colorCircle.selected::after {
    content: "✓";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    font-size: 20px;
    font-weight: bold;
}

.modalAreas-iconItem {
    font-size: 20px;
    cursor: pointer;
    padding: 6px;
    border: 2px solid transparent;
    border-radius: 10px;
    transition: all 0.2s ease;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.modalAreas-iconItem:hover {
    background-color: #f8f9ff;
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(52, 100, 204, 0.15);
}

.modalAreas-iconItem.selected {
    border-color: #3464cc;
    background-color: #eef2ff;
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(52, 100, 204, 0.2);
}

.modalAreas-centerSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.modalAreas-spinner {
    border: 4px solid rgba(52, 100, 204, 0.1);
    border-left-color: #3464cc;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.modalAreas-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalAreas-popupButton {
    padding: 10px 24px;
    border: none;
    border-radius: 8px;
    background-color: #3464cc;
    color: white;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.modalAreas-popupButton:hover {
    background-color: #2850a7;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(52, 100, 204, 0.2);
}

.modalAreas-popupButton:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .modalAreas-customizeContainer {
        flex-direction: column;
        gap: 10px;
        padding: 12px;
    }

    .modalAreas-colorSelection,
    .modalAreas-iconSelection {
        padding: 12px;
    }

    .modalAreas-popupContainer {
        width: 95%;
        margin: 8px;
        max-height: 90vh;
    }

    .modalAreas-colorOptions,
    .modalAreas-iconOptions {
        grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        gap: 8px;
        padding: 8px;
    }

    .modalAreas-colorCircle {
        width: 30px;
        height: 30px;
    }

    .modalAreas-iconItem {
        font-size: 24px;
        padding: 6px;
    }

    .modalAreas-colorSelection,
    .modalAreas-iconSelection {
        padding: 15px;
    }
}