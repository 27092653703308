/* Contenedor principal */
.customize-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  /* Contenedor principal */
.customize-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

/* Sección de selección de color e ícono */
.color-selection,
.icon-selection {
  flex: 1 1 300px;
  min-width: 0;
}

/* Título de las secciones */
.selection-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
}

/* Contenedor de opciones */
.color-options,
.icon-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  gap: 10px;
  justify-content: start;
}

/* Círculo de color */
.color-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
}

.color-circle:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.color-circle.selected {
  border-color: #333;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Ícono */
.icon-item {
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px;
  border-radius: 8px;
  color: #555;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
}

.icon-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.1);
}

.icon-item.selected {
  background-color: #333;
  color: #fff;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsividad */
@media (max-width: 600px) {
  .customize-container {
    flex-direction: column;
  }

  .color-selection,
  .icon-selection {
    width: 100%;
  }

  .color-options,
  .icon-options {
    grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  }

  .color-circle,
  .icon-item {
    width: 36px;
    height: 36px;
  }
}
  
  /* Acciones del formulario */
  .formActions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .formButton {
    padding: 10px 24px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .formButton:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .formButton[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .customize-container {
      flex-direction: column;
      align-items: center;
    }
  
    .formActions {
      flex-direction: column;
      gap: 15px;
    }
  }
  