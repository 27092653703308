.element-ws {
  border: 1px solid #e0e0e0;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: move;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  position: relative;
}

.checklist-container-ws {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checklist-container-ws input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 40px); /* Reduce el ancho para dar espacio a los íconos */
}

.checklist-container-ws button {
  padding: 8px;
  border: none;
  background-color: #090264;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.checklist-container-ws button:hover {
  background-color: #0056b3;
}

.task-list-ws {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task-list-ws li {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.task-list-ws li.completed-ws {
  text-decoration: line-through;
  background-color: #d4edda;
}

.task-text {
  flex-grow: 1;
}

.icons {
  display: none;
  gap: 8px;
}

.task-list-ws li:hover .icons {
  display: flex;
}

.edit-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 40px); /* Reduce el ancho para dar espacio a los íconos */
}
