.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .formWrapper {
    width: 300px; /* Puedes ajustar esto según lo necesites */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: #fff;
  }
  
  .googleBtn {
    background-color: #4285F4; /* Color de fondo de Google */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .googleBtn:before {
    content: url('https://pomofocus.io/icons/g-logo.png'); /* Asegúrate de poner la ruta correcta al logo de Google */
    margin-right: 10px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .hr {
    flex-grow: 1;
    height: 1px;
    background-color: #d3d3d3;
    border: none;
  }
  
  .or {
    padding: 0 10px;
    color: #808080;
  }
  
  .emailInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
  }
  
  .errRed {
    color: red;
    font-size: 12px;
  }