/* ImageBox.css */
.element {
  border: 1px solid #ccc;
  padding: 8px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: move;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.element:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.uploading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 8px;
}

.enlarged-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90%;
  border: 4px solid white;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ddd;
}

.button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #200a44;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 16px;
}

.button:hover {
  background-color: #2e105f;
  transform: scale(1.05);
}

.button .icon {
  margin-right: 8px;
}

.image-container {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
