.inputRegister {
    font-size: 0.8rem; /* Aproximadamente equivalente a calc(100vh / 60) para pantallas comunes */
    border: 1px solid #d5d6da;
    border-radius: 1.6rem; /* Ajustar según el diseño deseado */
    font-family: "Poppins", sans-serif;
    background-color: #f5f4fd;
    width: 100%;
    padding: 1vh; /* Aproximadamente equivalente a calc(100vh / 100) */
    box-sizing: border-box;
    margin-top: 1.5vh; /* Aproximadamente equivalente a calc(100vh / 65) */
  
    &:hover {
        background-color: #c09fe649;
        transition: all 0.3s ease-in-out;
    }
}

/* Media queries for different screen sizes to ensure responsiveness */

@media (max-width: 768px) {
    .inputRegister {
        font-size: 1rem; /* Slightly smaller font size for smaller screens */
        border-radius: 1.4rem;
        padding: 0.8vh;
        margin-top: 1.2vh;
    }
}

@media (max-width: 480px) {
    .inputRegister {
        font-size: 1.2rem; /* Even smaller font size for very small screens */
        border-radius: 1.2rem;
        padding: 0.6vh;
        margin-top: 1vh;
    }
}
