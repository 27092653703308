.audio-recorder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ButtonGrabacion {
  background-color: #240f5e;
  color: white;
  border: none;
  width: 70px; /* Ajusta el ancho */
  height: 70px; /* Ajusta la altura para que sea igual al ancho */
  font-size: 30px;
  border-radius: 50%; /* Asegura que el botón sea redondo */
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ButtonGrabacion:hover {
  background-color: #7d15df;
}

.ButtonGrabacion:active {
  transform: scale(1.1); /* Agranda el botón cuando está presionado */
}

.ButtonGrabacion.recording {
  background-color: #8B0000; /* Vinotinto */
}

.ButtonDescartar {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ButtonDescartar:hover {
  background-color: #c82333;
}

.ButtonDescartar:active {
  transform: scale(1.1);
}

.counter {
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 8px;
  border-radius: 12px;
  animation: bounce 1s infinite;
}

.audio-player {
  margin-top: 20px;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 8px;
  background-color: #6200ea;
  transition: width 0.5s;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

@keyframes spinner-bubble-animate {
  0% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }
  25% {
    transform: scale(1.1) translateX(-15px);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) translateX(15px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }
}

@keyframes spinner-color-cycle {
  0%, 100% {
    background-color: #03c5c3;
  }
  25% {
    background-color: #4b66ad;
  }
  50% {
    background-color: #b24fb1;
  }
  75% {
    background-color: #100522;
  }
}



.recording-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.audio-spectrum {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.bar {
  width: 4px;
  height: 10px;
  background-color: #333;
  margin: 0 2px;
  border-radius: 2px;
  animation: audio-spectrum-animation 1.2s infinite ease-in-out;
}

.bar:nth-child(1) {
  animation-delay: -1.2s;
}

.bar:nth-child(2) {
  animation-delay: -1.1s;
}

.bar:nth-child(3) {
  animation-delay: -1s;
}

.bar:nth-child(4) {
  animation-delay: -0.9s;
}

.bar:nth-child(5) {
  animation-delay: -0.8s;
}

.bar:nth-child(6) {
  animation-delay: -0.7s;
}

.bar:nth-child(7) {
  animation-delay: -0.6s;
}

.bar:nth-child(8) {
  animation-delay: -0.5s;
}

.bar:nth-child(9) {
  animation-delay: -0.4s;
}

.bar:nth-child(10) {
  animation-delay: -0.3s;
}

@keyframes audio-spectrum-animation {
  0%, 100% {
    height: 10px;
    background-color: #03ebf3;
  }
  50% {
    height: 30px;
    background-color: #6200a3;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.recordings-history {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}

.recordings-history h4 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.recordings-history .audio-player {
  margin-bottom: 10px;
}
