.miperfil-container {
  background-color: #f0f2f5;
  min-height: 84vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.miperfil-wrapper {
  max-height: 90vh;
  overflow-y: auto;
  width: 100%;
  max-width: 60rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.miperfil-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.miperfil-header {
  padding: 20px;
  background-color: #fbfbfb; /* Color neutro y minimalista */
  color: #240f5e; /* Texto oscuro */
  border-bottom: 1px solid #f5f5fc;
  margin-top: 30px;
}

.miperfil-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: #240f5e; /* Color de texto ajustado */
}

.miperfil-content {
  padding: 20px;
}

.miperfil-tabs {
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.miperfil-tab {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #240f5e;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  background-color: #f3f4f6;
  transition: color 0.2s, background-color 0.2s;
}

.miperfil-tab:hover {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #405564;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  background-color: #f3f4f6;
  transition: color 0.2s, background-color 0.2s;
}

.miperfil-tab-active {
  color: #333;
  background-color: #fff;
  border-bottom: 2px solid #333;
}

.miperfil-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.miperfil-image-wrapper {
  text-align: center;
}

.miperfil-image-container {
  position: relative;
}

.miperfil-image {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #93c5fd;
}

.miperfil-camera-button {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  background-color: #333; /* Botón de cámara en gris oscuro */
  color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.miperfil-button {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
}

.miperfil-button:hover {
  background-color: #444;
}

.miperfil-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.miperfil-icon {
  margin-right: 0.75rem;
  color: #333;
}

.miperfil-label {
  font-weight: 600;
  color: #333;
}

.miperfil-form-group {
  margin-bottom: 1rem;
}

.miperfil-form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.miperfil-select {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  background-color: #f9fafb;
  transition: border-color 0.2s;
}

.miperfil-select:focus {
  border-color: #333;
}

.miperfil-radio-group {
  margin-top: 0.5rem;
  display: flex;
  gap: 20px;
}

.miperfil-radio-label {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #333;
}

.miperfil-radio-input {
  margin-right: 0.5rem;
}

.dark-mode .miperfil-container {
  background-color: #1a1a1a;
}

.dark-mode .miperfil-wrapper {
  background-color: #2c2c2c;
}

.dark-mode .miperfil-card {
  background-color: #2c2c2c;
}

.dark-mode .miperfil-header {
  background-color: #333;
  color: #e0e0e0;
  border-bottom: 1px solid #444;
}

.dark-mode .miperfil-title {
  color: #e0e0e0;
}

.dark-mode .miperfil-tab {
  color: #e0e0e0;
  background-color: #444;
}

.dark-mode .miperfil-tab:hover {
  color: #fff;
  background-color: #555;
}

.dark-mode .miperfil-tab-active {
  color: #fff;
  background-color: #2c2c2c;
  border-bottom: 2px solid #fff;
}

.dark-mode .miperfil-image {
  border: 3px solid #4a4a4a;
}

.dark-mode .miperfil-camera-button {
  background-color: #555;
}

.dark-mode .miperfil-button {
  background-color: #4a4a4a;
  color: #fff;
}

.dark-mode .miperfil-button:hover {
  background-color: #555;
}

.dark-mode .miperfil-icon {
  color: #e0e0e0;
}

.dark-mode .miperfil-label {
  color: #e0e0e0;
}

.dark-mode .miperfil-select {
  border: 1px solid #555;
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .miperfil-select:focus {
  border-color: #777;
}

.dark-mode .miperfil-radio-label {
  color: #e0e0e0;
}

.dark-mode .miperfil-info-item {
  color: #e0e0e0;
}