.boardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 90%;
  margin: auto;
}

.board {
  flex: 0 1 calc(25% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  color: #9c109f;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.3s ease;
  padding: 10px;
  border: 1px solid #ded2ff;
  box-shadow: 0px 4px 18.5px rgba(0, 0, 0, 0.25);
  background: linear-gradient(135deg, #f3f4f6, #ffffff);
  margin-bottom: 17px;
  text-align: center;
}

.board:hover {
  color: #9c109f;
  box-shadow: 0px 8px 18.5px rgba(0, 0, 0, 0.25);
  transform: translateY(-4px);
}

.boardTitle {
  font-weight: 500;
  margin-top: 10px;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #736c60;
  max-width: 120px;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 17px;
}

.board-title_ {
  font-size: 13px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limita a 2 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: #9c109f;
  transition: color 0.3s ease;
}

.board:hover .boardIcon {
  color: #9c109f;
}

.addNewBoard {
  flex: 0 1 calc(25% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  color: #9c109f;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  background: #f3f4f6;
  transition: all 0.3s ease;
  padding: 10px;
  border: 2px dashed #9c109f;
  box-shadow: 0px 4px 18.5px rgba(0, 0, 0, 0.25);
  margin-bottom: 17px;
  text-align: center;
}

.addNewBoard::before {
  content: "+";
  font-size: 48px;
  font-weight: bold;
  color: #9c109f;
}

.addNewBoard:hover {
  background: #9c109f;
  color: #ffffff;
  box-shadow: 0px 8px 18.5px rgba(0, 0, 0, 0.25);
  transform: translateY(-4px);
}

.addNewBoard:hover::before {
  color: #ffffff;
}

.allAreasBoard {
  flex: 0 1 calc(25% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  color: #9c109f;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  background: #e6e6fa;
  transition: all 0.3s ease;
  padding: 10px;
  border: 2px solid #9c109f;
  box-shadow: 0px 4px 18.5px rgba(0, 0, 0, 0.25);
  margin-bottom: 17px;
  text-align: center;
}

.allAreasBoard::before {
  content: "👓";
  font-size: 48px;
  font-weight: bold;
  color: #9c109f;
}

.allAreasBoard:hover {
  background: #9c109f;
  color: #ffffff;
  box-shadow: 0px 8px 18.5px rgba(0, 0, 0, 0.25);
  transform: translateY(-4px);
}

