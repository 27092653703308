.attendee-selector {
  margin: 1rem 0;
}

.attendee-input-container {
  position: relative;
  width: 100%;
}

.attendee-input {
  width: 100%;
  padding: 8px 12px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.attendee-input.valid {
  border-color: #10b981;
  background-color: #f0fdf4;
}

.attendee-input.invalid {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.attendee-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 100, 204, 0.1);
}

.attendee-input.valid:focus {
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
}

.attendee-input.invalid:focus {
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.1);
}

.email-suggestion {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
}

.email-suggestion:hover {
  background-color: #f8fafc;
  transform: translateY(-1px);
}

.email-suggestion svg {
  color: #64748b;
}

.attendees-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.attendee-tag {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f1f5f9;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 14px;
}

.remove-attendee {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: #64748b;
  transition: color 0.2s ease;
}

.remove-attendee:hover {
  color: #ef4444;
}

.attendee-error {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #ef4444;
  font-size: 14px;
  margin-top: 8px;
}

.meet-option {
  margin-top: 16px;
}

/* Modo oscuro */
.dark .email-suggestion {
  background-color: #1f2937;
  border-color: #374151;
}

.dark .email-suggestion:hover {
  background-color: #2d3748;
}

.dark .attendee-input {
  background-color: #1f2937;
  border-color: #374151;
  color: #e5e7eb;
}

.dark .attendee-tag {
  background-color: #374151;
  color: #e5e7eb;
}

.dark .remove-attendee {
  color: #9ca3af;
}

.dark .remove-attendee:hover {
  color: #ef4444;
}

/* Responsive */
@media (max-width: 640px) {
  .email-suggestion {
    font-size: 14px;
  }
}

.validation-message {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  padding: 8px 12px;
  background-color: #fff7ed;
  border: 1px solid #fed7aa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: #c2410c;
  z-index: 11;
}

.validation-message svg {
  color: #f97316;
}

.email-suggestion {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 4px);
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10;
}

.validation-message + .email-suggestion {
  top: calc(100% + 44px);
}

/* Modo oscuro */
.dark .validation-message {
  background-color: #451a03;
  border-color: #9a3412;
  color: #fed7aa;
}

.dark .validation-message svg {
  color: #f97316;
}

/* Reutilizamos los estilos del toggle-slider para mantener consistencia */
.meet-option .all-day-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  height: 48px;
  background-color: #f8faff;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
}

.meet-option .all-day-toggle:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

.meet-option .all-day-label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #334155;
  font-weight: 500;
  font-size: 0.8rem;
}

.meet-option .all-day-checkbox {
  opacity: 0;
  position: absolute;
  right: 16px;
  width: 48px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  z-index: 1;
}

.meet-option .toggle-slider {
  position: relative;
  width: 48px;
  height: 24px;
  background-color: #e2e8f0;
  border-radius: 24px;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.meet-option .toggle-slider::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.meet-option .all-day-checkbox:checked + .toggle-slider {
  background-color: #3464cc;
}

.meet-option .all-day-checkbox:checked + .toggle-slider::before {
  transform: translateX(24px);
}

/* Estilo para modo oscuro */
.dark-mode .meet-option .all-day-toggle {
  background-color: #1e293b;
  border-color: #334155;
}

.dark-mode .meet-option .all-day-toggle:hover {
  background-color: #263449;
  border-color: #475569;
}

.dark-mode .meet-option .all-day-label {
  color: #e2e8f0;
}

.dark-mode .meet-option .toggle-slider {
  background-color: #475569;
}

.dark-mode .meet-option .all-day-checkbox:checked + .toggle-slider {
  background-color: #4a7ae0;
} 