.plansContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    flex-wrap: wrap; /* Allow wrapping on small screens */
  }
  
  .plan {
    flex: 1;
    margin: 10px;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .plan input {
    display: none;
  }
  
  .planDetails {
    cursor: pointer;
  }
  
  .planDetails h3 {
    margin: 0;
    color: #240f5e;
    font-size: 1.5em;
  }
  
  .planDetails .price {
    margin: 10px 0;
    color: #240f5e;
    font-size: 2em;
    font-weight: bold;
  }
  
  .planDetails p {
    margin: 10px 0 0 0;
    color: #110433;
  }
  
  .plan:hover,
  .plan.selected {
    background-color: #e4e4e4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #4caf50;
  }
  
  @media (max-width: 768px) {
    .plansContainer {
      flex-direction: column;
    }
  
    .plan {
      margin: 10px 0;
    }
  }
  
  .formStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .formStep h2 {
    margin-bottom: 20px;
    color: #110433;
  }
  
  .formActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .formButton {
    background-color: #240f5e;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 30px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s;
  }
  
  .formButton:hover {
    background-color: #7d15df;
  }
  
  .formButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  