.manifest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .manifest-paper {
    background-color: #f9f2e8;
    padding: 40px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border: 1px solid #d3c5a9;
    position: relative;
    overflow: hidden;
    z-index: 1; /* Ensure this is above the ::before pseudo-element */
  }
  
  .manifest-paper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
      linear-gradient(#eee .1em, transparent .1em);
    background-size: 100% 1.7em;
    pointer-events: none;
    z-index: -1; /* Place this behind the content */
  }
  
  /* Ensure all content is above the background */
  .manifest-title,
  .manifest-intro,
  .manifest-list,
  .manifest-closing,
  .manifest-signature,
  .manifest-actions {
    position: relative;
    z-index: 2;
  }
  
  /* Specific styles for form elements to ensure they're above the lines */
  .manifest-select,
  .manifest-input,
  .manifest-sign-button {
    position: relative;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.7); /* Slight background to ensure readability */
  }
  
  .manifest-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #4a4a4a;
  }
  
  .manifest-intro, .manifest-closing {
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .manifest-name {
    font-weight: bold;
    color: #2c3e50;
  }
  
  .manifest-list {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .manifest-item {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .manifest-select, .manifest-input {
    margin: 0 10px;
    padding: 5px;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .manifest-signature {
    margin-top: 40px;
    text-align: right;
  }
  
  .manifest-sign-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .manifest-sign-button:hover {
    background-color: #45a049;
  }
  
  .manifest-sign-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .manifest-signed-text {
    margin-top: 10px;
    font-style: italic;
  }
  
  .manifest-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
  }
  
  .manifest-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .manifest-button-secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .manifest-button-primary {
    background-color: #007BFF;
    color: white;
  }
  
  .manifest-button:hover {
    opacity: 0.9;
  }
  
  .manifest-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 600px) {
    .manifest-paper {
      padding: 20px;
    }
    
    .manifest-actions {
      flex-direction: column;
      gap: 10px;
    }
  }

  .manifest-error-message {
  color: #d32f2f;
  background-color: #ffd7d7;
  border: 1px solid #d32f2f;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  
}