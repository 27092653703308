.to-workspace {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  .user-circle, .add-user-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer; /* Añade un cursor de puntero para indicar que es interactivo */
  }
  
  .user-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .user-photo-placeholder {
    font-size: 16px;
    color: #888;
  }
  
  .add-user-circle {
    background-color: #e0e0e0; /* Color diferente para el botón de agregar usuario */
    color: #333; /* Color del icono de adición */
  }
  