.nav-wrapper {
    background: #100522;
    width: 100%;
    top: 0;
    left: 0;
    height: calc(100vh / 17);
    transition: 0.5s;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: calc(100vh / 95);
    padding-bottom: calc(100vh / 95);
    margin: 0px;
  }
  
  @media screen and (max-width: 600px) {
    .nav-wrapper {
      background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(27, 19, 79, 1) 50%, rgba(47, 40, 115, 1) 100%);
      flex-direction: row;
      text-align: center;
      padding-top: 0px;
      padding-bottom: 0px;
      height: calc(100vh / 12);
      justify-content: center;
      margin: 0px;
    }
  }
  
  .photoNav {
    border-radius: 50%;
    width: calc(100vh / 22);
    height: calc(100vh / 22);
    margin-top: calc(100vh / 98);
    margin-bottom: 0px;
    margin-left: calc(100vh / 90);
    margin-right: calc(100vh / 90);
    cursor: pointer;
  }
  
  .photoNav:hover {
    box-sizing: border-box;
    border: 2px solid rgba(43, 185, 204, 0.877);
    transition: all 0.1s ease-in-out;
  }

  .photoNav-container {
    position: relative;
    display: inline-block;
  }
  
  .notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: calc(100vh / 100);
    display: none; /* Inicialmente oculto */
  }
  
  .photoNav-container[data-notification-count]:not([data-notification-count="0"]) .notification-badge {
    display: inline-block;
  }
  
  .logonav {
    width: calc(100vh / 6);
    height: calc(100vh / 6.5);
    margin-left: calc(100vh / 60);
    position: absolute;
    left: 0px;
    cursor: pointer;
  }
  
  .logonav img {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
  
  .rowNav {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    margin-left: calc(100vh / 90);
    margin-right: calc(100vh / 90);
  }
  
  .rowNav p {
    margin: 0;
    color: #d3d3d3b7;
    font-size: calc(100vh / 70);
    cursor: pointer;
  }
  
  .rowNav p:hover {
    color: #eeb200;
  }
  
  .rowNavSP p {
    margin: 0;
    color: #9d7501ed;
    font-size: calc(100vh / 70);
    cursor: pointer;
    font-weight: 600;
  }
  
  .rowNavSP p:hover {
    color: #eeb200;
  }
  
  .rowNav2 {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
    margin-left: calc(100vh / 200);
    margin-right: calc(100vh / 20);
  }
  
  .rowNav2 p {
    margin: 0;
    color: #d3d3d3b7;
    font-size: calc(100vh / 70);
    margin-top: calc(100vh / 50);
    cursor: pointer;
  }
  
  .rowNav2 p:hover {
    color: #eeb200;
  }
  
  .rowNavSP2 p {
    margin: 0;
    color: #9d7501ed;
    font-size: calc(100vh / 70);
    cursor: pointer;
    font-weight: 200;
  }
  
  .rowNavSP2 p:hover {
    color: #eeb200;
  }
  
  .rankingText {
    font-size: calc(100vh / 100);
  }
  
  .nav-title {
    color: #ffffff;
    font-size: calc(100vh/50);
    margin: 0;
    padding: 0;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .responsive-user-info {
    display: none;
    align-items: center;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-info .nav-title {
    margin-right: calc(100vh / 90);
  }
  
  .responsive-user-info {
    display: none;
    align-items: center;
  }
  
  .responsive-user-info .nav-title {
    margin-left: calc(100vh / 90);
  }
  
  @media only screen and (max-width: 768px) {
    .responsive-user-info {
      display: flex;
    }
  
    .user-info {
      display: none;
    }
  
    .logonav,
    .rowNav,
    .rowNav2,
    .rankingText,
    .puntosSoportick {
      display: none;
    }
  }