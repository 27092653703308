.infoTextMassive {
  color: #666666;
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.dark-mode .infoTextMassive {
  color: #b0b0b0;
}

.taskTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.875rem;
}



.taskTable th,
.taskTable td {
  padding: 0.8rem;
  text-align: center;
}

.taskTable th {
  background-color: #f9f9f9;
  color: #555555;
  font-weight: 600;
  border-bottom: 2px solid #eeeeee;
}

.dark-mode .taskTable th {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border-bottom-color: #444444;
}

.taskTable td {
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
}

.dark-mode .taskTable td {
  background-color: #1a1a1a;
  border-bottom-color: #333333;
  color: #d0d0d0;
}

.taskTable tbody tr:hover {
  background-color: #f5f5f5;
}

.dark-mode .taskTable tbody tr:hover {
  background-color: #2a2a2a;
}

.inputField,
.selectField,
.checkboxField {
  padding: 0.5rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 0.875rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.dark-mode .inputField,
.dark-mode .selectField,
.dark-mode .checkboxField {
  background-color: #2c2c2c;
  border-color: #444444;
  color: #e0e0e0;
}

.inputField:focus,
.selectField:focus,
.checkboxField:focus {
  border-color: #007bff;
}

.dark-mode .inputField:focus,
.dark-mode .selectField:focus,
.dark-mode .checkboxField:focus {
  border-color: #3498db;
}

.checkboxField {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.checkboxField:checked::after {
  content: '✔';
  font-size: 0.875rem;
  color: #007bff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dark-mode .checkboxField:checked::after {
  color: #3498db;
}

.task-incomplete {
  background-color: #ffe6e6;
}

.dark-mode .task-incomplete {
  background-color: #4a2626;
}

.task-complete {
  background-color: #fffcde;
}

.dark-mode .task-complete {
  background-color: #4a4a26;
}

.task-confirmed {
  background-color: rgba(54, 59, 97, 0.1);
  border-color: #363b61;
}

.dark-mode .task-confirmed {
  background-color: #264a33;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}



.success-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 8px;
  font-size: 0.875rem;
}

.dark-mode .success-message {
  background-color: #264a33;
  color: #a3d9b5;
  border-color: #2c5a3f;
}

.evento-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  border-radius: 16px;
  padding: 20px;
  margin: 16px 0;
  border: 1px solid rgba(45, 92, 165, 0.1);
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(45, 92, 165, 0.05);
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
}

.evento-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 92, 165, 0.08);
}

.evento-content {
  flex: 1;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(100% - 50px);
  align-items: center;
  text-align: center;
  min-width: 0;
  overflow: hidden;
}

.evento-titulo {
  font-weight: 600;
  color: #1a365d;
  font-size: 1.1rem;
  letter-spacing: -0.3px;
  line-height: 1.4;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  padding-right: 8px;
}

.evento-detalles {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.detalles-principales {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.area-tag, .priority-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: background-color 0.3s ease;
}

.area-tag {
  background-color: #ebf4ff;
  color: #2c5282;
}

.priority-tag {
  background-color: #fff5f5;
  color: #c53030;
}

.fecha-hora {
  font-size: 0.8rem;
  color: #718096;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f7fafc;
  padding: 6px 12px;
  border-radius: 6px;
  width: fit-content;
  margin-left: auto;
}

.dark-mode .fecha-hora {
  background-color: #2d3748;
  color: #a0aec0;
}

.evento-actions {
  display: flex;
  gap: 12px;
  padding-left: 8px;
  flex-shrink: 0;
  width: 40px;
  position: relative;
}

.evento-card.task-confirmed {
  background-color: #f0fff4;
  border: 2px solid #48bb78;
  position: relative;
  overflow: hidden;
}

.evento-card.task-confirmed::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #48bb78;
}

/* Modo oscuro */
.dark-mode .evento-card {
  background-color: #1a202c;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .evento-titulo {
  color: #e2e8f0;
}

.dark-mode .area-tag {
  background-color: #2d3748;
  color: #90cdf4;
}

.dark-mode .priority-tag {
  background-color: #2d3748;
  color: #fc8181;
}

.dark-mode .check-button {
  background-color: #2d3748;
  color: #90cdf4;
}

.dark-mode .check-button.selected {
  background-color: #4299e1;
  color: white;
}

.dark-mode .evento-card.task-confirmed {
  background-color: #1c4532;
  border-color: #48bb78;
}

.edit-event-section {
  background: white;
  border-radius: 16px;
  padding: 28px;
  box-shadow: 0 4px 20px rgba(45, 92, 165, 0.08);
  margin: 20px 0;
  border: 1px solid rgba(45, 92, 165, 0.1);
}

.edit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6f0ff;
}

.edit-header h3 {
  font-size: 1.2rem;
  color: #2d5ca5;
  font-weight: 600;
  letter-spacing: -0.3px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-form-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-field label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #4a72b5;
  font-weight: 500;
}

.form-field label svg {
  color: #6b89c1;
}

.form-field input,
.form-field select {
  padding: 12px 16px;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  color: #2d5ca5;
  background-color: #f8faff;
  transition: all 0.2s ease;
}

.form-field input:focus,
.form-field select:focus {
  outline: none;
  border-color: #2d5ca5;
  box-shadow: 0 0 0 3px rgba(45, 92, 165, 0.1);
  background-color: #ffffff;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 28px;
  padding: 0;
}

.form-actions button {
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.save-edittask-button {
  background-color: #2d5ca5;
  color: white;
  border: none;
}

.save-edittask-button:hover {
  background-color: #234780;
  transform: translateY(-1px);
}

.cancel-button {
  background-color: #f0f7ff;
  color: #2d5ca5;
  border: 1px solid #e6f0ff;
}

.cancel-button:hover {
  background-color: #e6f0ff;
  transform: translateY(-1px);
}

/* Modo oscuro */
.dark-mode .edit-event-section {
  background-color: #1a2942;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .edit-header {
  border-bottom-color: #2d4157;
}

.dark-mode .edit-header h3 {
  color: #a3bce2;
}

.dark-mode .form-field label {
  color: #8ca2c7;
}

.dark-mode .form-field label svg {
  color: #6b89c1;
}

.dark-mode .form-field input,
.dark-mode .form-field select {
  background-color: #233047;
  border-color: #2d4157;
  color: #e6f0ff;
}

.dark-mode .form-field input:focus,
.dark-mode .form-field select:focus {
  border-color: #4a7ae0;
  background-color: #2a3b5c;
}

/* Responsive */
@media (max-width: 768px) {
  .edit-event-section {
    padding: 20px;
    margin: 16px 0;
  }

  .edit-form-content {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .form-actions {
    flex-direction: column;
    margin-top: 24px;
  }

  .form-field input,
.form-field select {

  min-width: auto;
}

  .form-actions button {
    width: 100%;
    justify-content: center;
  }

  .evento-titulo {
    font-size: 1rem;
    margin-top: 4px;
  }

  .evento-content {
    padding: 0 4px;
  }
  
  .detalles-principales {
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
  }

  .evento-content {
    width: calc(100% - 40px);
    padding: 0;
  }

  .evento-actions {
    width: 35px;
  }

  .evento-titulo {
    font-size: 0.95rem;
    margin-top: 4px;
  }
}

.check-button-eventtask {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #e2e8f0;
  background-color: white;
  color: #cbd5e0;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.check-button-eventtask:hover {
  border-color: #2b6cb0;
  color: #2b6cb0;
  background-color: #ebf8ff;
}

.check-button-eventtask-selected {
  background-color: #2b6cb0;
  border-color: #2b6cb0;
  color: white;
}

/* Modo oscuro */
.dark-mode .check-button-eventtask {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #a0aec0;
}

.dark-mode .check-button-eventtask:hover {
  border-color: #4299e1;
  color: #4299e1;
  background-color: #2d3748;
}

.dark-mode .check-button-eventtask-selected {
  background-color: #4299e1;
  border-color: #4299e1;
  color: white;
}

.delete-button {
  display: none;
}

.popupButton {
  width: 100%;
  padding: 12px;
  background-color: #2d5ca5;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
  transition: all 0.2s ease;
}

.popupButton:hover {
  background-color: #234780;
}

.popupButton.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.popupButton.disabled:hover {
  background-color: #cccccc;
}

/* Modo oscuro */
.dark-mode .popupButton.disabled {
  background-color: #444444;
}

.edit-button-eventtask {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: #666;
  flex-shrink: 0;
}

.edit-button-eventtask:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #363b61;
}

.form-field select {
  padding: 12px 16px;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  background-color: #f8faff;
  transition: all 0.2s ease;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236b89c1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.form-field select option {
  padding: 12px;
  font-size: 0.95rem;
}

/* Modo oscuro */
.dark-mode .form-field select {
  background-color: #233047;
  border-color: #2d4157;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238ca2c7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dark-mode .form-field select option {
  background-color: #1a2942;
}

.popupContent {
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}