:root {
    --primary-color: #34495e;
    --secondary-color: #f8f9fa;
    --accent-color: #3498db;
    --text-color: #333;
    --light-text-color: #888;
  }
  
  .chat-workspace {
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 500px;
    width: 350px;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .chat-header {
    padding: 5px;
    background-color: var(--primary-color);
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    display: flex;
    margin-bottom: 15px;
  }
  
  .chat-message-sender {
    justify-content: flex-end;
  }
  
  .chat-message-receiver {
    justify-content: flex-start;
  }
  
  .chat-profile-pic {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  
  .chat-message-content {
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .chat-message-text {
    background-color: var(--secondary-color);
    padding: 10px;
    border-radius: 10px;
    font-size: 0.9em;
    color: var(--text-color);
    line-height: 1.4;
  }
  
  .chat-message-time {
    font-size: 0.8em;
    color: var(--light-text-color);
    margin-top: 5px;
  }
  
  .chat-input {
    display: flex;
    padding: 15px;
    background-color: var(--secondary-color);
    border-top: 1px solid #eee;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .input-text {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: white;
    font-size: 0.9em;
    color: var(--text-color);
    outline: none;
  }
  
  .send-button {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .send-button:hover {
    background-color: #2980b9;
  }
  