.tooltipPlanning-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltipPlanning-icon {
    background-color: #e4e4e4; /* Gris claro */
    color: #4B0082; /* Morado oscuro */
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
  }
  
  .tooltipPlanning-text {
    visibility: hidden;
    width: 200px;
    background-color: #dcd0ff; /* Morado claro */
    color: #4B0082; /* Morado oscuro */
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Ajusta esta propiedad según necesites */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px; /* Tipografía pequeña */
    font-weight: normal;
  }
  
  .tooltipPlanning-container:hover .tooltipPlanning-text {
    visibility: visible;
    opacity: 1;
  }
  