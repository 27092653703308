.crearPerfilContainer {
  width: 100%;
  min-height: calc(100vh - 60px); /* Ajusta según la altura de tu footer */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Evita el scroll */
}

.progressCircles-loginform {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.circle-loginform {
  width: 40px;
  height: 40px;
  background-color: #e4e4e4;
  color: #4B0082;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: background-color 0.3s, color 0.3s;
}

.circle-loginform.active {
  background-color: #4B0082;
  color: white;
}

.circle-loginform.completed {
  background-color: #4B0082;
  color: white;
}

.bar-loginform {
  width: 50px;
  height: 5px;
  background-color: #e4e4e4;
  margin: 0 5px;
  transition: background-color 0.3s, width 0.3s;
}

.bar-loginform.active {
  background-color: #4B0082;
}

.formStepContainer {
  max-width: 600px;
}

.formStepContainer.forward {
  animation: slideInForward 0.5s ease-out;
}

.formStepContainer.backward {
  animation: slideInBackward 0.5s ease-out;
}

@keyframes slideInForward {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInBackward {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.formStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.formStep h2 {
  margin-bottom: 20px;
  color: #110433;
}

.formInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #e4f0f3;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  color: #240f5e;
  margin-bottom: 20px;
}

.formInput:focus {
  outline: none;
  border: 1px solid #240f5e;
  box-shadow: 0 0 0 2px rgba(128, 132, 182, 0.2);
}

.errorBox {
  background-color: #fdecea;
  color: #b20000;
  border: 1px solid #b20000;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.formActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.formButton {
  background-color: #240f5e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s;
}

.formButton:hover {
  background-color: #7d15df;
}

@media (max-width: 768px) {
  .progressCircles-loginform {
    display: none; /* Oculta los círculos en pantallas pequeñas */
  }

  .areaGoals-container {
    flex-direction: column;
    align-items: center;
  }

  .areas-column, .goals-column {
    width: 100%;
    margin-bottom: 20px;
  }

  .goal-input-container {
    width: 100%;
  }
}
