.menu_ws {
  position: absolute;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 220px;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu_list_ws {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu_item_ws {
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.menu_item_ws:hover {
  background-color: #f2f2f2;
  transform: translateX(4px);
}

.menu_button_ws {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu_button_ws:hover {
  background-color: #0056b3;
}
