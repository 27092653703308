.recurrence-selector {
  width: 100%;
}

.recurrence-modal-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.recurrence-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.recurrence-form h3 {
  margin: -32px -32px 32px -32px;
  padding: 20px 32px;
  background: linear-gradient(135deg, #001f3f 0%, #0a3d91 100%);
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 16px 16px 0 0;
}

.recurrence-form-group {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.recurrence-form-group label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.recurrence-input {
  width: 60px;
  padding: 10px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  background-color: #f7f8fc;
  color: #333;
  transition: all 0.3s ease;
}

.recurrence-select {
  padding: 10px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  background-color: #f7f8fc;
  color: #333;
  transition: all 0.3s ease;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.recurrence-input:focus,
.recurrence-select:focus {
  outline: none;
  border-color: #3464cc;
  box-shadow: 0 0 0 3px rgba(52, 100, 204, 0.1);
}

.recurrence-weekday-selector {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  padding: 8px;
}

.recurrence-weekday-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.recurrence-weekday-label input[type="checkbox"] {
  display: none;
}

.recurrence-weekday-text {
  width: 44px;
  height: 44px;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: white;
  border: 2px solid #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.recurrence-weekday-label input[type="checkbox"]:checked + .recurrence-weekday-text {
  background: linear-gradient(135deg, #001f3f 0%, #0a3d91 100%);
  border-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 31, 63, 0.2);
  transform: translateY(-2px);
}

.recurrence-actions {
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #e9ecef;
}

.recurrence-button {
  padding: 12px 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.recurrence-button-primary {
  background: linear-gradient(135deg, #001f3f 0%, #0a3d91 100%);
  box-shadow: 0 4px 12px rgba(0, 31, 63, 0.2);
}

.recurrence-button-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 31, 63, 0.3);
}

.recurrence-button-secondary {
  background-color: #e9ecef;
  color: #495057;
}

.recurrence-button-secondary:hover {
  background-color: #dee2e6;
  transform: translateY(-2px);
}

.recurrence-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

/* Dark mode */
.dark-mode .recurrence-modal-content {
  background-color: #1a1a1a;
}

.dark-mode .recurrence-form h3,
.dark-mode .recurrence-form-group label {
  color: #e0e0e0;
}

.dark-mode .recurrence-input,
.dark-mode .recurrence-select,
.dark-mode .recurrence-weekday-text {
  background-color: #333;
  border-color: #444;
  color: #e0e0e0;
}

.dark-mode .recurrence-button-secondary {
  background-color: #333;
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .recurrence-modal-content {
    padding: 24px;
    width: 95%;
  }

  .recurrence-form h3 {
    margin: -24px -24px 24px -24px;
    padding: 16px 24px;
  }

  .recurrence-form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .recurrence-weekday-text {
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
}

.end-config-container {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.count-input-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.count-input-container input {
  width: 60px;
}

.count-input-container span {
  white-space: nowrap;
}

.recurrence-input {
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.recurrence-select {
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.end-config-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

/* En modo oscuro */
.dark-mode .end-config-label {
  color: #e0e0e0;
} 

.react-datepicker {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Unificar el color del header */
.react-datepicker__header {
  background-color: #09133f;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-top: 12px;
  position: relative;
  border-bottom: none;
}

.react-datepicker__day-names {
  margin-bottom: 0;
  padding: 8px 0;
}

/* Estilos para el texto del mes y año */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: white !important;
  font-weight: 500;
}

/* Estilos para los días */
.react-datepicker__day {
  width: 2rem;
  line-height: 2rem;
  margin: 0.2rem;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.react-datepicker__day:hover {
  background-color: rgba(67, 97, 238, 0.1);
  color: #4361ee;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #3a23bb !important;
  color: white !important;
  font-weight: 500;
}

/* Estilos para los selectores de mes y año */
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year {
  color: white !important;
}

/* Flechas de navegación */
.react-datepicker__navigation-icon::before {
  border-color: white !important;
}

/* Estilos para los dropdowns */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: rgba(67, 97, 238, 0.1);
  color: #4361ee;
}

/* Contenedor de días de la semana */
.weekly-days-container {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.section-label {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 16px;
  display: block;
}

/* Contenedor de opciones de finalización */
.end-options-container {
  background-color: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
}

.end-options-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.end-type-select {
  min-width: 150px;
}

.count-input-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.count-input {
  width: 80px;
  text-align: center;
}

.count-label {
  font-size: 1rem;
  color: #495057;
}

/* Estilos para el DatePicker */
.date-picker-input {
  min-width: 150px;
}

@keyframes recurrenceFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(-40px);
    filter: blur(10px);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.02) translateY(5px);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: blur(0px);
  }
}

.recurrence-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  backdrop-filter: blur(5px);
  padding: 20px;
  box-sizing: border-box;
  animation: recurrenceBackgroundFadeIn 0.4s ease-out;
}

.recurrence-modal {
  background-color: #ffffff;
  border-radius: 16px;
  width: 600px;
  min-width: 300px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: recurrenceFadeIn 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  margin: auto;
  position: relative;
}

.recurrence-header {
  margin: -24px -24px 24px -24px;
  padding: 16px 24px;
  background-color: #3464cc;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 16px 16px 0 0;
}

.recurrence-content {
  padding: 24px;
  color: #333;
  overflow-y: auto;
}

.recurrence-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.recurrence-section-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 16px;
}

.recurrence-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.recurrence-day-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recurrence-day-input {
  display: none;
}

.recurrence-day-text {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background-color: white;
  border: 2px solid #e6f0ff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.recurrence-day-input:checked + .recurrence-day-text {
  background-color: #3464cc;
  color: white;
  border-color: #3464cc;
}

.recurrence-end-options {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: nowrap;
}

.recurrence-select {
  padding: 12px 16px;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  background-color: #f8faff;
  transition: all 0.2s ease;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%236b89c1%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  min-width: 140px;
}

.recurrence-input {
  padding: 12px 16px;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  background-color: #f8faff;
  width: 70px;
}

.recurrence-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e9ecef;
}

.recurrence-btn {
  padding: 12px 24px;
  font-weight: 500;
  font-size: 0.95rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.recurrence-btn-primary {
  background-color: #3464cc;
  color: white;
}

.recurrence-btn-secondary {
  background-color: #f1f5f9;
  color: #475569;
}

/* Responsive */
@media (max-width: 768px) {
  .recurrence-modal {
    width: calc(100% - 32px);
    margin: 16px;
  }

  .recurrence-content {
    padding: 16px;
  }

  .recurrence-section {
    padding: 16px;
  }

  .recurrence-weekdays {
    gap: 4px;
    padding: 2px;
  }

  .recurrence-day-text {
    width: 36px;
    height: 36px;
    font-size: 0.9rem;
  }

  .recurrence-select,
  .recurrence-input {
    width: 100%;
    min-width: 0;
  }

  .recurrence-end-options {
    flex-wrap: wrap;
  }

  .recurrence-select,
  .recurrence-datepicker,
  .recurrence-input {
    width: 100% !important;
    min-width: 0 !important;
    max-width: none !important;
  }
}

/* Dark mode */
.dark-mode .recurrence-modal {
  background-color: #1a1a1a;
}

.dark-mode .recurrence-section {
  background-color: #2d2d2d;
}

.dark-mode .recurrence-day-text {
  background-color: #333;
  border-color: #444;
  color: #e0e0e0;
}

.dark-mode .recurrence-select,
.dark-mode .recurrence-input {
  background-color: #1e293b;
  border-color: #334155;
  color: #e2e8f0;
}

@keyframes recurrenceBackgroundFadeIn {
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
}

/* Agregar estos estilos */
.recurrence-date-container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.recurrence-date-label {
  font-size: 0.95rem;
  color: #475569;
  white-space: nowrap;
  min-width: fit-content;
}

.recurrence-datepicker {
  width: 180px !important;
  min-width: 180px !important;
}

/* Para modo oscuro */
.dark-mode .recurrence-date-label {
  color: #e2e8f0;
}

.recurrence-count-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.recurrence-count-wrapper .recurrence-input {
  width: 80px;
  min-width: 80px;
}