.image-box {
    position: relative;
    width: 100px;
    height: 110px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }
  
  .detalleTarea__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: #ffffff; /* Cambia el color según tus necesidades */
  }
  
  .detalleTarea__loading .icon {
    font-size: 24px; /* Tamaño del ícono */
    margin-bottom: 5px;
  }
  