.containerCalendars {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentCalendars {
  flex: 1;
  margin-left: 80px; /* Ajusta según el ancho de tu sidebar */
  padding: 20px;
  height: calc(100vh - 60px); /* Ajusta según la altura de tu navbar */
  overflow: hidden;
  background: #f8f9fa;
}

.gestion-calendarios-container {
  height: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  margin: 0 auto;
  overflow: hidden;
  margin-right: 38px;
}

.gestion-calendarios-header {
  text-align: center;
  flex-shrink: 0; /* Evita que el header se encoja */
  margin-top: 2.3rem;
}

.calendarios-scroll {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 40px;
}

/* Estilizar el scrollbar */
.calendarios-scroll::-webkit-scrollbar {
  width: 8px;
}

.calendarios-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.calendarios-scroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.calendarios-scroll::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.calendarios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 20px; /* Espacio para el botón de guardar */
}

.calendario-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calendario-card.active {
  border-color: #4715d1;
}

.calendario-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.calendario-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.calendario-header h3 {
  margin: 0;
  color: #240f5e;
  font-size: 1.1rem;
  flex: 1;
  padding-right: 1rem;
}

.calendario-email {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4715d1;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.toggle-button:hover {
  background: rgba(71, 21, 209, 0.1);
}

.toggle-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.color-selector {
  margin-top: 1rem;
  padding: 0rem;
  border-top: 1px solid #eee;
}

.color-selector span {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
  font-size: 0.9rem;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.5rem;
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.color-option:hover {
  transform: scale(1.2);
}

.color-option.selected {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #240f5e;
}

.save-changes-button {
  position: sticky;
  bottom: 20px; /* Ajustado para dar más espacio en la parte inferior */
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  min-width: 200px; /* Ancho mínimo para el botón */
  background-color: #2d5ca5;
  color: white;
  border: none;
  z-index: 10;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  display: block; /* Asegura que el botón se comporte como bloque */
  margin-top: 20px;
  font-weight: 500;
  font-size: 1rem;
}

.save-changes-button:hover {
  background-color: #240f5e;
}

.save-changes-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.gestion-calendarios-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px; /* Aumentado para dar más espacio en el centro */
  width: 100%;
  padding: 20px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #2d5ca5; /* Cambiado para coincidir con el color del botón */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Ajustes responsive */
@media (max-width: 768px) {
  .gestion-calendarios-container {
    max-height: calc(100vh - 80px);
    padding: 10px;
    margin-top: 40px;
    margin-right: 0px;
  }

  .gestion-calendarios-header {

    margin-top: 0rem;
    padding: 1rem;
  }

  .calendarios-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .calendario-card {
    padding: 1rem;
  }

  .color-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.import-calendar-section {
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.import-form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.import-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.import-button {
  padding: 10px 20px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.import-button:hover {
  background-color: #3367d6;
}

.import-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.calendario-card.imported {
  border: 2px solid #ef5350;
}

.calendario-card .import-form {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.calendario-card .import-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.calendario-card .import-button {
  width: 100%;
  padding: 8px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.calendario-card .import-button:disabled {
  background-color: #ccc;
}

.imported-badge {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
}

.import-button {
  width: 100%;
  padding: 10px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.import-button:hover {
  background-color: #3367d6;
}

.import-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
} 