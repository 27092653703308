.boardsContainerWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinea los items al inicio */
  height: 100vh;
  width: 100%;
}

.businessSection{
  margin-top: 40px;
}

@media (max-width: 1008px) {
  .boardsContainerWrapper {
  
    height: 70vh;
  }

  .businessSection{
    margin-top: 20px;
  }
}

.boardsContainer {
  width: 80%; /* Ajustado al 80% de ancho */
  height: 60%; /* Ajustado al 80% de alto */
  border-radius: 20px; /* Bordes redondeados */
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* .selectBusinessContainer {
  display: flex;
  flex-direction: column;
}

.selectBusinessContent {
  flex: 1;
} */



.board_ {
  width: 242px;
  height: 133px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 21px;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.businessName {
  text-align: center;
  color: #77639a;
}

.board_:hover {
  filter: brightness(1.1);
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.board-content_ {
  display: flex;
  align-items: center;
  height: calc(100% - 25px);
}

.board-icon_ {
  width: 100px;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 21px;
  background-color: rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.board-text_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4px;
  height: 100%;
  padding: 7px;
  box-sizing: border-box;
}

.board-title_ {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap; /* Limita a una sola línea */
  overflow: hidden;
  text-overflow: ellipsis;
}


.board-description_ {
  font-size: 10px;
  margin-top: 4px;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Número máximo de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userType_ {
  font-size: 9px;
  font-weight: 600;
  text-align: right;
  margin-top: auto;
}

.adminText_ {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: 0 0 21px 21px;
  color: inherit;
}

.adminText_ span {
  font-size: 10px;
  flex-grow: 1;
}

.adminText_ .left {
  text-align: left;
  margin-left: 10px;
}

.adminText_ .right {
  text-align: center;
}

.addNewBoard_ {
  background-color: #ffffff;
  display: flex;
  width: 200px;
  max-height: 93px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.addNewBoard_:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.addBoardText_ {
  cursor: pointer;
  margin: 10px;
  color: #6e6e6e;
  font-size: 16px;
}

.addBoardPlus_ {
  background-color: #d9d9d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: #6e6e6e;
  font-size: 24px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.addBoardPlus_:hover {
  background-color: #b0b0b0;
  color: #4a4a4a;
}

.spinner-container_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.spinner-bubble_ {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: spinner-bubble-animate_ 1.5s linear infinite,
    spinner-color-cycle_ 6s ease-in-out infinite;
}

@keyframes spinner-bubble-animate_ {
  0% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }
  25% {
    transform: scale(1.1) translateX(-15px);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) translateX(15px);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }
}

@keyframes spinner-color-cycle_ {
  0%,
  100% {
    background-color: #03c5c3;
  }
  25% {
    background-color: #4b66ad;
  }
  50% {
    background-color: #b24fb1;
  }
  75% {
    background-color: #100522;
  }
}

.dropdown-menu-board_ {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(92, 92, 92, 0.473);
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.dropdown-item2_ {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
  color: aliceblue;
}

.dropdown-item2_:hover {
  background-color: #130853;
  color: white;
  border-radius: 50%;
  width: 50%;
  height: 50%;
}

@media (max-width: 1008px) {
  .board_ {
    flex: 0 1 100%;
  }
}

.detalleTarea__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #333; /* Cambia el color según tus necesidades */
}

.detalleTarea__loading .icon {
  font-size: 24px; /* Tamaño del ícono */
  margin-right: 10px;
}
