.calendarSelection h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.calendarSelection {
  background: rgba(255, 255, 255, 0.9);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.calendarOption {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 12px 15px;
  background: white;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  gap: 15px;
  position: relative;
  overflow: visible;
}

.calendarOption:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.calendarOption .calendar-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.calendarOption .color-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.color-hint {
  font-size: 12px;
  color: #666;
  font-style: italic;
  margin-top: 2px;
}

.calendarOption input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.calendarOption label {
  color: #333;
  cursor: pointer;
}

.calendarOption label[for]:hover {
  color: #240f5e;
}

.syncButton {
  background-color: #240f5e;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.syncButton:hover {
  background-color: #7d15df;
}

.syncButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.formActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
}

.formButton {
  background-color: #240f5e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.formButton:hover {
  background-color: #7d15df;
}

.formButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingMessage {
  margin-top: 10px;
  color: #4715d1;
  font-size: 20px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.successMessage {
  background-color: #f0f7ff;
  color: #1a5f7a;
  font-size: 1rem;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #cce4ff;
  box-shadow: 0 2px 8px rgba(37, 99, 235, 0.05);
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
  animation: fadeScale 0.4s ease-out;
}

@keyframes fadeScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.successMessage::before {
  content: "✨";
  font-size: 1.2rem;
}

.successMessage span {
  font-weight: 500;
}

.color-picker-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.selected-color {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 0 2px #e0e0e0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.color-palette {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 12px;
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 100;
  margin-right: 10px;
}

.color-picker-container:hover .color-palette {
  opacity: 1;
  visibility: visible;
}

.color-option {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  position: relative;
}

.color-option:hover {
  transform: scale(1.2);
  box-shadow: 0 0 0 2px white, 0 0 0 4px currentColor;
}

.color-option.selected {
  box-shadow: 0 0 0 2px white, 0 0 0 4px #240f5e;
}

.color-option.selected::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 2px solid white;
}

/* Animación suave para el hover */
@keyframes scaleIn {
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.color-palette {
  animation: scaleIn 0.2s ease;
}

/* Mejora en la interacción del contenedor de calendario */
.calendarOption {
  position: relative;
  overflow: visible;
}

/* Asegurar que el color-picker esté siempre visible */
.calendarOption:hover {
  z-index: 10;
}

.calendar-subtitle {
  background-color: #fff3dc;
  color: #8b6f3d;
  font-size: 0.95rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  padding: 12px 16px;
  border-radius: 8px;
  border-left: 4px solid #ffd684;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Estilo para calendarios seleccionados */
.calendarOption.selected {
  border-color: #a7b5ff;
  background-color: #f5f8ff;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(167, 181, 255, 0.15);
}

/* Estilo para el checkbox cuando está seleccionado */
.calendarOption.selected input[type="checkbox"] {
  accent-color: #6b7fff;
}

/* Estilo para el label cuando está seleccionado */
.calendarOption.selected label {
  color: #4a5899;
  font-weight: 500;
}
