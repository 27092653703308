
  
  .welcomeStep h1 {
    color: #240f5e; /* Título en color principal */
    margin-bottom: 20px;
  }
  
  .welcomeStep h2 {
    color: #110433; /* Subtítulo en color secundario */
    margin-bottom: 20px;
  }
  
  .welcomeStep p {
    font-size: 16px;
    color: #666666; /* Texto de párrafo en color gris oscuro */
    margin-bottom: 20px;
  }
  
  .welcomeButton {
    padding: 10px 20px;
    background-color: #240f5e; /* Botón en color principal */
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s;
  }
  
  .welcomeButton:hover {
    background-color: #7d15df; /* Color al pasar el mouse */
  }
  
  @media (max-width: 768px) {
    .welcomeStep {
      padding: 20px;
      max-width: 90%;
    }
  
    .welcomeButton {
      width: 100%;
    }
  }
  