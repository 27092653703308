/* Contenedor de navegación */
.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco muy suave */
  justify-content: center; /* Centrar las tabs */
  margin: 0;
  padding: 0;
}

/* Estilo de las tabs */
.nav-tabs {
  border-bottom: none; /* Sin borde inferior */
  margin-bottom: 10px; /* Espacio reducido entre las tabs y el contenido */
}

/* Estilo de cada ítem de la navegación */
.nav-item {
  margin: 5px 3px; /* Márgenes más pequeños */
}

/* Estilo para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .nav-item {
    margin: 8px 0; /* Ajuste de márgenes */
    text-align: center; /* Centrar el contenido de cada tab */
  }

  .nav-link {
    width: 100%; /* Hacer que los links ocupen todo el ancho */
    padding: 8px 0; /* Ajustar el padding para que sea más compacto */
  }
}

/* Estilo de los links de navegación */
.nav-link {
  display: block;
  padding: 6px 10px; /* Padding reducido */
  background-color: #f0f0f0; /* Fondo muy claro */
  color: #595959; /* Color de texto sutil */
  border-radius: 15px; /* Bordes ligeramente redondeados */
  font-size: 10px; /* Tamaño de fuente reducido */
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease; /* Transiciones más rápidas */
  margin: 1px;
  min-width: 80px; /* Ancho mínimo reducido */
}

/* Efectos de foco y hover */
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
  background-color: #e2e2e2; /* Fondo al hacer hover */
  color: #404040; /* Color de texto al hacer hover */
  cursor: pointer;
  transform: translateY(-1px); /* Sutil elevación al hacer hover */
}

/* Estilo para links deshabilitados */
.nav-link.disabled {
  color: #a0a0a0;
  background-color: #f7f7f7;
  border-color: #ddd;
}

/* Estilo específico para tabs activas */
.nav-tabs .nav-link.active {
  background-color: #dbdada; /* Fondo más suave */
  color: #333; /* Texto ligeramente más oscuro */
  border-color: #ccc; /* Borde sutil */
  border-radius: 15px; /* Bordes más redondeados */
  transform: scale(1.02); /* Ligera ampliación para destacar la tab activa */
}

/* Estilo para tab content */
.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

/* Estilo para imagen de ficha */
.imgFicha {
  width: calc(100vh / 7); /* Tamaño reducido */
  height: calc(100vh / 7);
  border-radius: 50%; /* Bordes completamente redondeados */
  margin: calc(100vh / 100); /* Margen reducido */
  padding: calc(100vh / 400); /* Padding reducido */
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  border: 4px solid rgba(255, 255, 255, 0.2); /* Borde más sutil */
  transition: opacity 0.2s ease, transform 0.2s ease; /* Transiciones más rápidas */
}

.imgFicha:hover {
  opacity: 0.9; /* Menor cambio de opacidad */
  transform: scale(1.03); /* Menor escala al hacer hover */
}

/* Estilo para tabla de ficha */
.tableFicha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 16px;
  padding: 8px; /* Espacio reducido */
  font-size: 0.9rem;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1); /* Fondo muy claro */
  backdrop-filter: blur(8px); /* Menor desenfoque */
}

.tableFicha td {
  padding: 4px; /* Espacio reducido entre celdas */
}

/* Estilo para columna de ficha */
.columnaFicha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  color: #e0e0e0; /* Color de texto sutil */
  box-sizing: border-box;
}

/* Estilo para formulario de parámetros */
.parametrosForm {
  background-color: rgba(255, 255, 255, 0.1); /* Fondo ligeramente más claro */
  padding: 20px; /* Espacio reducido */
  border-radius: 16px; /* Bordes redondeados */
  backdrop-filter: blur(8px); /* Menor desenfoque */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Sombra más sutil */
}

.parametrosLabel {
  color: #d0d0d0; /* Texto sutil */
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.parametrosSelect {
  background-color: rgba(255, 255, 255, 0.6);
  color: #707070; /* Color de texto sutil */
  border: none;
  border-radius: 8px;
  padding: 8px; /* Padding reducido */
  font-size: 0.8rem;
  width: 100%;
}

.parametrosRadio {
  display: flex;
  gap: 15px; /* Espacio reducido entre elementos */
}

.parametrosRadioItem {
  color: #d0d0d0; /* Texto sutil */
  font-size: 0.7rem;
}

/* Botón de parámetros */
.parametrosButton {
  background-color: #6a4c93; /* Color sutil */
  border: 1px solid #5a3a7d;
  color: #fff; /* Texto blanco */
  padding: 8px 16px; /* Tamaño de botón reducido */
  border-radius: 8px; /* Bordes redondeados */
  font-size: 0.9rem; /* Tamaño de fuente reducido */
  transition: background-color 0.2s ease, transform 0.2s ease; /* Transiciones rápidas */
  align-self: center;
  margin: auto;
  width: 100%;
  margin-top: 8px; /* Espacio reducido superior */
}

.parametrosButton:hover {
  background-color: #5a3a7d; /* Color más oscuro al hacer hover */
  transform: translateY(-2px); /* Ligero efecto de elevación */
}

/* Estilos existentes */
/* ... (mantén todos los estilos existentes) ... */

/* Estilos para el modo oscuro */
.dark-mode .nav {
  background-color: rgba(30, 30, 30, 0.9);
}

.dark-mode .nav-link {
  background-color: #2c2c2c;
  border-color: #444;
  color: #b0b0b0;
}

.dark-mode .nav-link:focus,
.dark-mode .nav-link:hover {
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.dark-mode .nav-link.disabled {
  color: #707070;
  background-color: #252525;
  border-color: #444;
}

.dark-mode .nav-tabs .nav-link.active {
  background-color: #4a4a4a;
  color: #ffffff;
  border-color: #555;
}

.dark-mode .imgFicha {
  border-color: rgba(70, 70, 70, 0.2);
}

.dark-mode .tableFicha {
  border-color: rgba(70, 70, 70, 0.2);
  background-color: rgba(30, 30, 30, 0.1);
}

.dark-mode .columnaFicha {
  color: #b0b0b0;
}

.dark-mode .parametrosForm {
  background-color: rgba(30, 30, 30, 0.1);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.05);
}

.dark-mode .parametrosLabel {
  color: #b0b0b0;
}

.dark-mode .parametrosSelect {
  background-color: rgba(50, 50, 50, 0.6);
  color: #e0e0e0;
}

.dark-mode .parametrosRadioItem {
  color: #b0b0b0;
}

.dark-mode .parametrosButton {
  background-color: #4a3670;
  border-color: #3a2a60;
}

.dark-mode .parametrosButton:hover {
  background-color: #3a2a60;
}