@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
$fondo: #ecebf5;
$item-hover: #f9f8fb;
$container-shadow: rgba(180, 180, 194, 0.2) 0px calc(100vh/90);
$color-texto: #00071d;
$color-principal: #03c5c3;
$color-principal-hover: #038b89;
$color-secundario: #4b66ad;
$color-secundario-hover: #33487e;
$color-terciario: #471046;
$color-terciario-hover: #eee5ed7c;
$color-cuaternario: #100522;
$color-quinto: #5723aa;
$color-terciario-hover2: #a879a7;


html, body {
  width: 100%;
  margin: 0;
  height: 100%;
  font-family: "Poppins", sans-serif;
  background-color: $fondo;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

* {
  font-family: "Poppins", sans-serif;
}

.container {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: calc(100vh/30);
  margin: auto;
  padding: calc(100vh/100);
  height: 100%;
  max-width: calc(100vh/0.8);
  background-color: rgb(255, 255, 255);
  margin-top: calc(100vh/30);
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-top: calc(100vh/11);
  }

}

.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.container2 {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  border-radius: calc(100vh/30);
  margin: calc(100vh/80);
  padding: calc(100vh/100);
  height: calc(100vh/1.8);
  width: calc(100vh/1);
  background-color: rgb(255, 255, 255);
  margin-top: calc(100vh/30);
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-top: calc(100vh/11);
  }



}

.row {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.row-space {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 0;

  @media screen and (max-width: 1000px) {
    width: 90%;
    flex-direction: column;
  }
}

.googleBtn {
  background-color: #fff;
  /* Fondo blanco con bordes para el botón de Google */
  border: 1px solid #d5d6da;
  /* Borde sutil */
  display: flex;
  /* Para alinear el icono y el texto */
  align-items: center;
  justify-content: center;
}

.formContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: inflate 0.8s ease-out;

  .formWrapper {
    background-color: white;
    padding: calc(100vh/30) calc(100vh/20);
    border-radius: calc(100vh/40);
    display: flex;
    flex-direction: column;
    gap: calc(100vh/200);
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra sutil para el formulario */
    margin: 4%;
    max-width: calc(100vh/3);


    form {
      display: flex;
      flex-direction: column;
      gap: calc(100vh/120);
      color: $color-texto;

      input {
        font-size: calc(100vh/60);
        border: 1px solid #d5d6da;
        border-radius: calc(100vh/60);
        font-family: "Poppins", sans-serif;
        background-color: #f5f4fd;
        width: 94%;
        padding: calc(100vh/100);

        &:hover {
          background-color: #f7dbcf;
          transition: all 0.3s ease-in-out;
        }
      }

      Label {
        font-size: calc(100vh/90);
        font-family: "Poppins", sans-serif;
        color: #5a5959;
        padding: calc(100vh/100);
        margin-bottom: calc(100vh/200);
      }

      button {
        background-color: #1b134f;
        border: 0px solid transparent;
        border-radius: calc(100vh/60);
        padding: calc(100vh/100) calc(100vh/30);
        font-size: calc(100vh/70);
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $color-secundario;
          box-shadow: $container-shadow;
          transform: translate(0px, -5px);
          border: 1px solid black;
        }
      }

    }



    .logo {
      font-size: calc(100vh/30);
      color: $color-texto;
      text-align: center;
    }

    .logoLogin {
      width: calc(100vh/6.5);
    }

    .addimg {
      cursor: pointer;
      background-color: #ff6600;
      max-width: 20%;
      max-height: 20%;
    }

    .foto {
      display: flex;
      text-align: center;
      font-size: 18px;
      width: auto;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 6px;

      &:hover {
        background-color: #f7dbcf;
        transition: all 0.3s ease-in-out;

      }
    }

    .fotocargada {
      display: flex;
      text-align: center;
      font-size: 18px;
      width: auto;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 6px;
      background-color: #c5ddc7;

      &:hover {
        background-color: #f7dbcf;
        transition: all 0.3s ease-in-out;

      }
    }
  }
}

.errOk {
  color: #0f921a;
  text-align: center;
  font-size: calc(100vh/70);
  margin: 0px;
  padding: 0px;
}


.errRed {
  color: #f57c77;
  text-align: center;
  font-size: calc(100vh/80);
  margin: 0px;
  padding: 0px;
}

.nav-wrapper {
  background: $color-cuaternario;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100vh/17);
  transition: 0.5s;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: calc(100vh/95);
  padding-bottom: calc(100vh/95);
  margin: 0px;

  /*   // colapsa el navbar en pantallas pequeñas
  @media screen and (max-width: 600px) {
    background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(27, 19, 79, 1) 50%, rgba(47, 40, 115, 1) 100%);
    flex-direction: row;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
    height: calc(100vh/12);
    justify-content: center;
    margin: 0px;
  } */



  box-shadow: $container-shadow;
  /* 
  .nav-title {
    color: #ffffff;
    font-size: calc(100vh/40);
    margin: 0;
    padding: 0;
  } */

  /*   .photoNav {
    border-radius: 50%;
    width: calc(100vh/22);
    height: calc(100vh/22);
    margin-top: calc(100vh/98);
    margin-bottom: 0px;
    margin-left: calc(100vh/90);
    margin-right: calc(100vh/90);
    cursor: pointer;



    &:hover {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 2px solid rgba(43, 185, 204, 0.877);
      transition: all 0.1s ease-in-out;
      box-sizing: border-box;

    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  } */

  /* .logonav {
    width: calc(100vh/6);
    height: calc(100vh/6.5);
    margin-left: calc(100vh/60);
    position: absolute;
    left: 0px;
    cursor: pointer;

    .logonav img {
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  } */

  /*   .notification {
    background-color: $color-terciario;
    color: white;
    text-decoration: none;
    position: relative;
    align-items: center;
    justify-content: center;
    align-self: auto;
    text-align: center;
    font-size: calc(100vh/50);
    padding: calc(100vh/97);
    border-radius: 50%;
    display: block;
    width: calc(100vh/38);
    height: calc(100vh/38);
    margin-right: calc(100vh/90);



    @media screen and (max-width: 600px) {
      margin-left: 5px;

    }
  } */

  /*   .notification:hover {
    background: rgb(255, 51, 0);
  }

  .notification .badge {
    position: absolute;
    top: -4px;
    right: -10px;
    font-size: 12px;
    padding: 1px 8px;
    border-radius: 50%;
    background: red;
    color: white;

    @media screen and (max-width: 1300px) {
      font-size: 8px;
      padding: 1px 6px;
      right: -5px;
    }
  }
 */
  .button-navbar {
    background-color: $color-principal;
    position: relative;
    color: #ffffff;
    cursor: pointer;
    border: 0px solid transparent;
    border-radius: 50%;
    font-size: calc(100vh/54);
    padding: calc(100vh/97);
    float: right;
    margin-right: calc(100vh/90);
    width: calc(100vh/22);
    height: calc(100vh/22);



    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .button-navbar:hover {
    transition: 0.5s;

    background-color: #5e6160;
  }



  .dropdown-navBar {
    z-index: 123;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: calc(100vh/25);
    right: calc(100vh/40);
    background-color: rgb(8, 10, 78);
    border-radius: calc(100vh/40);
    color: #FFFFFF;
    font-size: calc(100vh/60);
    padding: calc(100vh/160);
    margin-top: 10px;
    animation: inflate 0.08s ease-out;
  }

  .dropdown-item-nav {
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
    /* Añade otros estilos si lo deseas */
  }

  .dropdown-item-nav:hover {
    background-color: #2c9676;
    border-radius: 50px;

    /* Añade otros estilos si lo deseas */
  }

}

@keyframes inflate {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

///

.div-content {
  overflow: hidden;
  height: 100%;
  width: 70%;
  border-radius: 10px;
  background-color: #ffff;
  box-shadow: 1px 5px 20px 1px #d8d3d3;
  margin: auto;
  text-align: center;
  color: $color-cuaternario;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: center;
  padding: 1rem;

  @media screen and (max-width: 1000px) {
    width: 85%;
    margin-top: 100px;
  }
}


.circular-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-button {
  border-radius: 50%;
  width: calc(100vh/25);
  height: calc(100vh/25);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  background-color: #d3d0d0;
  color: rgb(92, 92, 92);

  &:hover {
    background-color: #fffbe4;
    color: rgb(92, 92, 92);
  }
}

.contenedorChat {
  position: relative;
  display: flex;
  width: 90%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: calc(100vh/50);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: calc(100vh/14);
    width: 100%;
    height: 100%;
  }

  h2 {
    margin-top: 2px;
    margin-bottom: 2px;
    color: $color-cuaternario;
  }
}

.quillContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 0px;
  box-sizing: border-box;

}

.quillEditor {
  width: 100%;
  justify-content: center;
  background-color: #ffffff;
  border: 0px;
  height: 100%;
  border-radius: calc(100vh/70);
  box-sizing: border-box;
}

.custom-quill-editor .ql-editor {
  font-size: calc(100vh/70);
  /* Cambia esto al tamaño de fuente que prefieras */
  min-height: calc(100vh/14);
  /* Cambia esto al alto que prefieras */
  border: 0px;

}

.custom-quill-toolbar .ql-snow .ql-picker-label,
.custom-quill-toolbar .ql-snow .ql-picker-item {
  font-size: calc(100vh/90);
  /* Cambia esto al tamaño de fuente que prefieras */
}

.custom-quill-toolbar .ql-toolbar svg {
  width: calc(100vh/50);
  /* Cambia esto al ancho que prefieras */
  max-height: calc(100vh/40);
  /* Cambia esto al alto que prefieras */
  justify-content: center;
  text-align: center;
  margin: 0px;
  padding: 0px;
  border: 0px;

}

.ql-container {
  border: none !important;
}

.ql-editor {
  border: none !important;
}

.ql-toolbar button,
.ql-toolbar select {
  margin: 0 calc(100vh/100);
}

.ql-snow {
  border: 0px solid #ccc;
}

.ql-snow .ql-toolbar {
  background-color: #F6F6F6;
  display: flex;
  margin: auto;
  padding: 0;
  height: calc(100vh/22);
  border-radius: calc(100vh/70);
  overflow-y: hidden;
  border: 0px;

  &:hover {
    transition: all 0.5s ease-in-out;
    background-color: #f8faf9;
    height: calc(100vh/10);
  }
}

.ql-snow .ql-toolbar button {
  margin: 0;
  padding: 0;
  border: 0px;
}

.chatInput {
  background-color: #eef9f9;
  position: relative;
  width: 100%;
  padding: calc(100vh/80);
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: calc(100vh/300) solid #e6ebea;
  box-sizing: border-box;

  button {
    background-color: #83c7c6;
    border-radius: calc(100vh/20) calc(100vh/20);
    border: 0px solid transparent;
    width: calc(100vh/20);
    width: calc(100vh/20);
    padding: calc(100vh/80);
    font-size: calc(100vh/50);
    margin-left: calc(100vh/90);
    cursor: pointer;

    color: #ffffff;
  }

  button:hover {
    background-color: $color-quinto;
  }

  .chati {
    padding: calc(100vh/80);
    font-size: calc(100vh/60);
    margin: calc(100vh/500);
    position: relative;
    display: block;
    width: 100%;
    border-radius: calc(100vh/40);
    border: 0px dashed rgb(2, 1, 39);
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .chati:focus {
    outline: none;

  }
}



.div-content-chat {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 60%;
  /* Modifica el width para sumar el 100% con los otros elementos */
  border-radius: 0px;
  background-color: #ffffff;
  margin: auto;
  display: flex;
  /* Agrega esta línea */
  flex-direction: column;
  /* Agrega esta línea */
  justify-content: space-between;
  /* Agrega esta línea */
  padding: 0px;
  order: 2;
  /* Agrega esta línea */
  flex-basis: 80%;
  /* Agrega esta línea */

  @media screen and (max-width: 768px) {
    order: 1;
    /* Agrega esta línea */
    flex-basis: 30%;
    /* Agrega esta línea */
    width: 100%;
    margin-top: 0px;
    border-radius: calc(100vh/60);
    padding: calc(100vh/90);
    box-sizing: border-box;
    margin-bottom: calc(100vh/70);

  }
}

@media screen and (max-width: 768px) {
  .contenedorChat {
    flex-direction: column;
    margin-top: calc(100vh / 11);
    width: 100%;
    height: 100%;
  }

  .bigContent {
    height: auto;
    /* Ajusta esta propiedad */
  }

  .div-content-chat {
    width: 100%;
    min-height: 80vh;
    /* Agrega esta línea */
  }

  .div-left-chat,
  .div-right-chat {
    width: 100%;
    margin-bottom: calc(100vh/20);
    height: 100%;

    @media screen and (max-width: 768px) {
      margin-botton: 200px;

    }
  }
}

.bigContent {
  width: 100vw;
  height: calc(90vh/1.1);
  margin: auto;
  padding: 0px;
  margin-top: calc(100vh/40);
  border-radius: calc(100vh/30);

  @media screen and (max-width: 768px) {
    width: 90vw;

  }
}

.div-right-chat {
  flex-basis: 100%;
  order: 3;
  flex-basis: 20%;
  /* Agrega esta línea */

  position: relative;
  height: 100%;
  width: 20%;
  border: 1px solid #EAE9EA;
  background-color: rgb(255, 255, 255);
  color: #000000;
  justify-content: center;
  margin: auto;
  box-sizing: border-box;
  border-radius: 0 10px 10px 0;

  /* Agrega box-sizing */
  @media screen and (max-width: 768px) {
    order: 2;
    flex-basis: 20%;
    width: 100%;
    border-radius: 0;
    min-height: calc(100vh/2);
    margin-bottom: calc(100vh/50);
  }
}

.div-left-chat {
  flex-basis: 20%;
  order: 1;
  position: relative;
  height: 100%;
  width: 20%;
  background: white;
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;

  /* Agrega box-sizing */
  @media screen and (max-width: 768px) {
    width: 100%;
    order: 3;
  }
}



.titleChatSegment {
  position: relative;
  /* Cambia de absolute a relative */
  top: 0px;
  left: 0px;
  width: 100%;
  height: calc(100vh/16);
  font-size: calc(100vh/60);
  padding: calc(100vh/100);
  font-weight: 400;
  align-items: center;
  background-color: #ffffff;
  color: #181818;
  border-bottom: #F8F8F8 calc(100vh/400) solid;
  justify-content: center;
  box-sizing: border-box;
  border-radius: calc(100vh/60) calc(100vh/60) calc(100vh/60) calc(100vh/60);
  /* Agrega box-sizing */
}

.titleChatSegmentLeft {
  position: relative;
  /* Cambia de absolute a relative */
  top: 0px;
  left: 0px;
  height: calc(100vh/17.6);
  font-size: calc(100vh/50);
  padding: calc(100vh/100);
  font-weight: 400;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-bottom: #5873AD calc(100vh/800) solid;
  justify-content: center;
  box-sizing: border-box;
  background-color: #5873AD;
  border-radius: calc(100vh/60) 0 0 0;
  box-shadow: 0px calc(100vh/200) calc(100vh/200) rgba(0, 0, 0, 0.25);
}

.titleChatSegmentWhite {
  position: relative;
  font-size: calc(100vh/70);
  padding: calc(100vh/100);
  font-weight: 400;
  align-items: center;
  text-align: center;
  color: #0f1950;
  border-bottom: #f8f6f623 calc(100vh/800) solid;
  border-top: #f8f6f623 calc(100vh/800) solid;
  justify-content: center;
  box-sizing: border-box;
  background-color: #cfdcf7;
  border-radius: 0 0 10px 0;
  margin: calc(100vh/150);
}




.shake-left-right {
  animation: shake-left-right 0.8s linear both
}

@keyframes shake-left-right {

  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%
  }

  10% {
    transform: rotate(8deg)
  }

  20%,
  40%,
  60% {
    transform: rotate(-10deg)
  }

  30%,
  50%,
  70% {
    transform: rotate(10deg)
  }

  80% {
    transform: rotate(-8deg)
  }

  90% {
    transform: rotate(8deg)
  }
}

.fechaVencimiento {
  margin-top: calc(100vh/100);
  font-size: calc(100vh/80);
  color: #575757;
}

.DescriptionMessage {
  position: relative;
  /* Cambia de absolute a relative */
  top: 0px;
  left: 0px;
  width: 90%;
  font-size: calc(100vh/70);
  padding: calc(100vh/100);
  margin: auto;
  margin-top: calc(100vh/90);
  margin-bottom: calc(100vh/90);
  max-height: calc(100vh/2.9);
  overflow-y: auto;
  align-items: center;
  background-color: #EEF9F9;
  color: #575757;
  border-bottom: #EEF9F9 calc(100vh/700) solid;
  justify-content: center;
  border-radius: calc(100vh/90);
  box-sizing: border-box;
  white-space: pre-wrap;
}

.DescriptionMessage:hover {
  background-color: #cfdcf7;
  transition: all 0.3s ease-in-out;
}

.cardPolitics {
  position: relative;
  padding: 0rem;
  border: solid 1px #ebe8e8;
  width: 100%;
  margin: auto;
  bottom: 1rem;
  left: inherit;
  right: inherit;
  max-width: calc(100vh/1);
  margin-top: calc(100vh/20);
  border-radius: 15px 15px;
}

.card {
  position: relative;
  padding: 0rem;
  border: solid 1px #ebe8e8;
  width: 100%;
  margin: auto;
  bottom: 1rem;
  left: inherit;
  right: inherit;
  max-width: calc(100vh/4);
  margin-top: calc(100vh/20);
  border-radius: 15px 15px;

  @media screen and (max-width: 1000px) {
    max-width: 500px;
    width: 100%;
  }

  @media screen and (max-width: 1300px) {
    height: 90%;
  }
}

.card:hover {
  background-color: #f8f3f3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: translate(0px, -5px);
}

.green-bg {
  background-color: rgb(225, 255, 224);
}

.red-bg {
  background-color: rgb(252, 227, 227);
}

.yellow-bg {
  background-color: rgb(245, 242, 213);
}

.gray-bg {
  background-color: rgba(255, 255, 255, 0.233);
}

.hover-message {
  position: absolute;
  top: (calc(100vh/22) * -1);
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* fondo negro con transparencia */
  color: #fff;
  /* texto blanco */
  padding: 5px;
  border-radius: 15px;
  visibility: hidden;
  /* Oculto por defecto */
  font-size: calc(100vh/80);
}

.card:hover .hover-message {
  visibility: visible;
  /* Se muestra cuando el mouse está encima de la tarjeta */
}

.hover-message2 {
  position: absolute;
  top: (calc(100vh/22) * -1);
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* fondo negro con transparencia */
  color: #fff;
  /* texto blanco */
  padding: 5px;
  border-radius: 15px;
  visibility: hidden;
  /* Oculto por defecto */
  font-size: calc(100vh/80);
}

.board:hover .hover-message2 {
  visibility: visible;
  /* Se muestra cuando el mouse está encima de la tarjeta */
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(1px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake-animation {
  animation: shake 1s;
  animation-iteration-count: infinite;
}


.footer {
  z-index: 998;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh / 55);
  background: #0c0b1b;
  color: #ffffff;
  font-size: calc(100vh / 80);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: calc(100vh / 90);
  margin: 0;
  border-top: calc(100vh/260) solid $color-terciario;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}





.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.logo-footer img {
  height: calc(100vh / 35);
  margin-right: calc(100vh / 90);
}

.footer-text p {
  margin: 0;
  color: #b3b3b3;
}

@media (max-width: 768px) {
  .footer {
    display: none;
  }}


@keyframes animaMenu {

  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(calc(100vh/80));
  }

  100% {
    transform: translatex(calc(100vh/100));
  }
}




.ticketsNuevos {
  color: #150541;
  overflow: hidden;
  height: 100%;
  width: 340px;
  border-radius: 10px;
  background-color: #ffffff;
  //box-shadow: 1px 5px 20px 1px #d8d3d3;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;

  h1 {
    font-size: 27px;
    font-weight: 500;
    padding: 0px;
    margin: auto;
  }

  h3 {
    font-size: 17px;
    padding: 0px;
    margin: auto;
    color: #0b0224;
  }

}






.ticketContent {
  display: flex;
  flex-direction: row;
}

.ticketDetails {
  border: 1px solid transparent;
  width: 200px;
  margin: auto;
  margin-top: 10px;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  flex-direction: column;
  font-size: 14px;

  @media screen and (max-width: 1300px) {
    font-size: 9px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.ticketTitle {
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ticketDate {
  background-color: #e3e3f8;
  border-radius: 15px;
  text-align: center;
  width: 90px;
  height: 88px;
  margin-top: 10px;
  border: 1px dashed #6f85f0;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
  }

  span {
    font-size: 15px;
    font-weight: 300;
    margin: 0px;
    text-overflow: ellipsis;
    margin-top: 4px;
  }

  @media screen and (max-width: 1300px) {
    background-color: #e3e3f8;
    border-radius: 7px;
    text-align: center;
    width: 180px;
    height: 56px;
    margin-top: 7px;
    border: 1px dashed #6f85f0;

    h1 {
      font-size: 15px;
      font-weight: 600;
      margin: 0px;
    }

    span {
      font-size: 10px;
      font-weight: 300;
      margin: 3px;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }

  @media screen and (max-width: 600px) {
    background-color: #e3e3f8;
    border-radius: 15px;
    text-align: center;
    width: 90px;
    height: 88px;
    margin-top: 10px;
    border: 1px dashed #6f85f0;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 0px;
    }

    span {
      font-size: 15px;
      font-weight: 300;
      margin: 0px;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }
}

.ticketDateDefeated {
  background-color: #ffe5e5;
  border-radius: 15px;
  text-align: center;
  width: 90px;
  height: 88px;
  color: rgb(170, 7, 7);
  margin-top: 10px;
  border: 1px dashed #f55f5f;

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
  }

  span {
    font-size: 15px;
    font-weight: 300;
    margin: 0px;
    text-overflow: ellipsis;
    margin-top: 4px;
  }

  @media screen and (max-width: 1300px) {
    background-color: #ffe5e5;
    border-radius: 7px;
    text-align: center;
    width: 180px;
    height: 56px;
    margin-top: 7px;
    color: rgb(170, 7, 7);
    border: 1px dashed #f55f5f;

    h1 {
      font-size: 15px;
      font-weight: 600;
      margin: 0px;
    }

    span {
      font-size: 10px;
      font-weight: 300;
      margin: 3px;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }

  @media screen and (max-width: 600px) {
    background-color: #ffe5e5;
    border-radius: 15px;
    text-align: center;
    width: 90px;
    height: 88px;
    color: rgb(170, 7, 7);
    margin-top: 10px;
    border: 1px dashed #f55f5f;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 0px;
    }

    span {
      font-size: 15px;
      font-weight: 300;
      margin: 0px;
      text-overflow: ellipsis;
      margin-top: 4px;
    }
  }
}



.tickeNewWrapper {
  background-color: #21D4FD;
  background-image: radial-gradient(circle farthest-corner at 1.3% 2.8%, rgba(239, 249, 249, 1) 0%, rgba(182, 199, 226, 1) 100.2%);
  color: rgb(231, 245, 255);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-weight: 700;
  justify-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0px;
  position: absolute;

  .TitleNew {
    letter-spacing: 2px;
    background-color: #ff4800;
    color: rgb(255, 255, 255);
    border-radius: 30px 30px 10px 10px;
    font-weight: 500;
    width: 100%;
    border: #00071d 1px dashed;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  }

  .nuevoTicket {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    width: 20%;
    height: 60%;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    border-radius: 30px 30px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    text-align: center;
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(48, 49, 142, 1) 0%, rgba(94, 93, 223, 1) 90%);
    background-attachment: fixed;
    border: 1px dashed rgb(2, 1, 39);

    @media screen and (max-width: 1500px) {
      width: 70%;
      height: 60%;

    }

    p {
      font-size: 13px;
      font-weight: 700;
      margin: 0px;
      letter-spacing: 1px;
      text-overflow: ellipsis;
      margin-bottom: 10px;
      color: #ffffff;
    }


    .InputT {
      padding: 0.4rem;
      font-size: 20px;
      margin: 0.2rem;
      text-align: center;
      position: relative;
      display: block;
      font-size: 19px;
      width: 300px;
      border-radius: 10px;
      border: 1px dashed rgb(2, 1, 39);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    span {
      font-size: 20px;
      font-weight: 100;
      margin: 10px;
      letter-spacing: 2px;

      text-overflow: ellipsis;
      margin-top: 4px;
    }

    button {
      margin: 10px;
      width: 100px;
      background-color: #f06c00;
      padding: 0.5rem;
      border-radius: 10px;
      border: 0px solid transparent;
      font-size: 16px;
      color: white;
      font-size: 17px;
      cursor: pointer;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }

    button:hover {
      background-color: #ff4c0f;
    }

    .return {
      margin: 10px;
      width: 100px;
      background-color: #00ff55;
      padding: 0.5rem;
      border-radius: 10px;
      border: 0px solid transparent;
      font-size: 16px;
      color: rgb(7, 0, 34);
      font-size: 17px;
      cursor: pointer;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    }
  }
}

.ticketsNuevos__pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding: 0rem;
  border-radius: 10px;
  border: 0px solid rgb(235, 232, 232);
}

.btnAnterior {
  background-color: #240f5e;
  color: white;
  border: none;
  border-radius: calc(100vh/120);
  margin-right: 0;
  cursor: pointer;
  padding: 6px 14px;
  font-size: calc(100vh/80);
  width: auto;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btnAnterior:hover {
  background-color: #1e094d;
  transform: translateY(-2px);
}

.btnAnterior:active {
  background-color: #1c0848;
  transform: translateY(0);
}

.btnAnterior:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(36, 15, 94, 0.5);
}

.btnSiguiente {
  background-color: #240f5e;
  color: white;
  border: none;
  border-radius: calc(100vh/120);
  margin-right: 0;
  cursor: pointer;
  padding: 6px 14px;
  font-size: calc(100vh/80);
  width: auto;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btnSiguiente:hover {
  background-color: #1e094d;
  transform: translateY(-2px);
}

.btnSiguiente:active {
  background-color: #1c0848;
  transform: translateY(0);
}

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(36, 15, 94, 0.5);
}

.titleChat {
  z-index: 1;
  background-color: #0F1950;
  color: #f3f6f8;
  margin: 0px;
  padding: calc(100vh/50);
  text-align: center;
  height: calc(100vh/60);
  border-radius: 0px calc(100vh/70) calc(100vh/70) 0px;
  box-shadow: 0px calc(100vh/200) calc(100vh/200) rgba(0, 0, 0, 0.25);
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
  font-size: calc(100vh/60);

  @media screen and (max-width: 768) {
    border-radius: 10px;
  }

  h1 {
    font-size: calc(100vh/60);
    font-weight: 700;
    margin: 0px;
    margin-bottom: 0px;
    letter-spacing: calc(100vh/600);
    overflow: hidden;
  }

  .descripcion {
    background-color: #e8eef1;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 6px;
    padding: 0.4rem;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);


    width: 80%;
    height: 80%;
    margin: auto;
    color: #020f3a;
    justify-self: center;
    justify-content: center;
  }

}

/* Estilo de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px;
  /* Ancho de la barra de desplazamiento */
}

/* Estilo de la pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: transparent;
  /* Fondo de la pista */
}

/* Estilo del pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  /* Color del pulgar con transparencia */
  border-radius: 4px;
  /* Redondear los bordes del pulgar */
}

/* Estilo del pulgar de la barra de desplazamiento al pasar el ratón */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
  /* Color del pulgar al pasar el ratón con menos transparencia */
}

.chatContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: #f5f5f533;
  margin-bottom: calc(100vh/90);
  padding: calc(100vh/60);
  overflow: scroll;
  flex: 1;

  .mensaje {
    text-align: left;
    margin: 0px;
  }

  .manesajeUsuario {
    background-color: #EAF7FF;
    color: #00071d;
    padding: calc(100vh/120);
    border-radius: calc(100vh/90);
    margin-top: calc(100vh/160);
    text-align: left;
    white-space: inherit;
    text-overflow: ellipsis;
    margin-right: calc(100vh/90);
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    float: left;
    box-shadow: 0px calc(100vh/520) calc(100vh/520) rgba(0, 0, 0, 0.25);
    font-size: calc(100vh/76);

    .hraChat {
      color: #2d0a8d;
      margin: 0px;
      text-align: right;
      font-size: calc(100vh/90);
    }

    .nameChat {
      color: #2d0a8d;
      text-align: left;
      font-size: calc(100vh/90);
    }
  }

  .mensajeOriginal {
    background-color: #ffeafe;
    color: #00071d;
    padding: calc(100vh/120);
    border-radius: calc(100vh/90);
    margin-top: calc(100vh/160);
    text-align: left;
    white-space: inherit;
    text-overflow: ellipsis;
    margin-right: calc(100vh/90);
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    float: left;
    box-shadow: 0px calc(100vh/520) calc(100vh/520) rgba(0, 0, 0, 0.25);
    font-size: calc(100vh/76);

    .hraChat {
      color: #2d0a8d;
      margin: 0px;
      text-align: right;
      font-size: calc(100vh/90);
    }
  }

  .mensajeCliente {
    background-color: #FFE9E2;
    color: #00071d;
    border-radius: calc(100vh/90);
    margin-top: calc(100vh/160);
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: calc(100vh/90);
    float: right;
    box-shadow: 0px calc(100vh/520) calc(100vh/520) rgba(0, 0, 0, 0.25);
    font-size: calc(100vh/76);

    .hraChat {
      color: #b4440f;
      margin: 0px;
      text-align: right;
      font-size: calc(100vh/90);
    }
  }
}



.noMessages {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #7a7a7a;
  width: 100%;
}


.chatDropMenu {
  background-color: #3928A5;
  color: rgb(206, 204, 204);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 150ms linear;
  height: 0px;
  overflow: hidden;
}

.chatDropMenu.active {
  height: calc(100vh/100);
  transition: all 150ms linear;
}

.dropBtn {
  background-color: #0050c7;
  height: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-weight: 1000;
  cursor: pointer;
  padding: 0.3rem;
}

.etiquetas {
  align-self: center;
  display: flex;
  flex-direction: row;
}




.lblChatRed {
  width: 120px;
  background-color: #53a4aa;
  color: #ffffff;
  font-size: 11px;
  margin: 0px;
  padding: 0.5rem;
  border-radius: 10px;
  margin-left: 10px;
}

.lblChatGreen {
  background-color: #6765e7;
  color: #ffffff;
  font-size: 11px;
  margin: 0px;
  padding: 0.5rem;
  border-radius: 10px;
  margin-left: 10px;
  width: 120px;
}

.lblChatYellow {
  background-color: #1179b6;
  color: #ffffff;
  font-size: 11px;
  margin: 0px;
  padding: 0.5rem;
  border-radius: 10px;
  margin-left: 10px;
  width: 120px;
}

.closeTicketBtn {
  background-color: $color-quinto;
  border-radius: 50px 50px;
  border: 0px solid transparent;
  width: 140px;
  padding: 5px;
  margin-top: 33px;
  font-size: 16px;
  justify-self: center;
  cursor: pointer;

  color: #ffffff;
}

.modalTitle {
  font-size: 30px;
  background-color: $color-secundario;
  color: #ffffff;
  text-align: center;
  padding: 0.4rem;
  font-family: "Poppins", sans-serif;

  border-radius: 15px;
}

.modalTeam {
  font-size: 30px;
  background-color: #7f0575;
  color: #ffffff;
  text-align: center;
  padding: 0.4rem;
  font-family: "Poppins", sans-serif;

  border-radius: 15px;
}

.buttonModalYes {
  background-color: #7f0575;
  border-radius: 10px 10px;
  border: 0px solid transparent;
  width: 100px;
  padding: 5px;
  margin-top: 33px;
  font-size: 16px;
  justify-self: center;
  cursor: pointer;
  color: #ffffff;
  margin-right: 10px;
}

.buttonModalNot {
  font-family: "Poppins", sans-serif;
  background-color: $color-quinto;
  border-radius: 10px 10px;
  border: 0px solid transparent;
  width: 100px;
  padding: 5px;
  margin-top: 33px;
  font-size: 16px;
  justify-self: center;
  cursor: pointer;
  color: #ffffff;
}

.textCenter {
  text-align: center;
}

.buttonsModal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem;
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
  opacity: 1;
}

.fade-Out {
  animation: fadeOut 1s ease-in-out;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.logo-init-container {
  width: auto;
  height: auto;
  margin-left: calc(100vh/60);
  transform: scale(0.4);
}

.logo-init-container img {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s;
  /* Agregar transición para el efecto fade-out */
  z-index: 1000;
}

.fade-out {
  opacity: 0;
}


.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes spinner-bubble-animate {
  0% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }

  25% {
    transform: scale(1.1) translateX(-15px);
    opacity: 1;
  }

  50% {
    transform: scale(1.2) translateX(15px);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateX(0);
    opacity: 0.5;
  }
}

@keyframes spinner-color-cycle {

  0%,
  100% {
    background-color: #03c5c3;
    /* color-principal */
  }

  25% {
    background-color: #4b66ad;
    /* color-secundario */
  }

  50% {
    background-color: #b24fb1;
    /* color-terciario */
  }

  75% {
    background-color: #100522;
    /* color-cuaternario */
  }
}

.spinner {
  width: 28px;
  height: 28px;
  border: 3px solid rgba(50, 50, 50, 0.1); /* Color del fondo del anillo */
  border-top: 3px solid #320d5e; /* Color del anillo animado */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animación de giro */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}






.bigCard {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  /* Puedes ajustar la duración de la animación */
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  background-color: #ffffff;
  color: #000000;
  border-radius: calc(100vh/40);
  max-width: calc(100vh/1);
  max-height: 100%;
  margin: auto;
  margin-top: calc(100vh/70);
  margin-bottom: calc(100vh/170);
  display: flex;
  padding: calc(100vh/40);
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    margin-top: calc(100vh/20);
    width: 85%;
    height: 100%;
  }
}

.bigCard2 {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  background-color: #ffffff;
  color: #000000;
  border-radius: calc(100vh/40);
  max-width: calc(100vh/0.65);
  max-height: 100%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: calc(100vh/170);
  display: flex;
  padding: calc(100vh/40);
  flex-direction: row;
  position: relative;
  left: 22px; /* Desplaza el elemento 45px a la derecha */
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    margin-top: calc(100vh/20);
    width: 85%;
    height: 100%;
    left: 0; /* Remueve el desplazamiento en pantallas pequeñas */
  }
}

.bigCard3 {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  /* Puedes ajustar la duración de la animación */
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  background-color: #ffffff;
  color: #000000;
  border-radius: calc(100vh/40);
  max-width: calc(100vh/0.65);
  max-height: 100%;
  margin: auto;
  margin-top: calc(100vh/70);
  margin-bottom: calc(100vh/170);
  display: flex;
  padding: calc(100vh/40);
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    margin-top: calc(100vh/20);
    width: 85%;
    height: 100%;
  }
}

.bigCardContent {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.colums {
  display: flex;
  flex-direction: column;
}

.inputNameBusiness {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  width: 100%;
  background-color: #ffffff;

  @media screen and (max-width: 1300px) {
    margin: 1px;
    padding: 1px;
    font-size: 10px;
  }
}

.prueba {
  font-size: 10px;
  width: 100px;
}

.leftDivBigCard {
  position: relative;
  left: 0px;
  align-items: center;
  justify-content: center;
  margin: 0px;
  align-self: normal;
  text-align: center;
  width: 80px;
  height: 100%;

  @media screen and (max-width: 1300px) {
    width: 60px;
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 10px;
  }

}

.centerDivBigCard {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.columBigCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}

.hrAgents {
  width: 100%;
  border: calc(100vh/700) solid #00000015;
  margin: calc(100vh/80)
}

.agenteResponsable {
  color: #FFFFFF;
  background-color: #188bbf82;
  border-radius: 15px;
  border: 0px solid transparent;
  display: inline-block;
  /* Ajuste clave aquí */
  font-size: calc(100vh/60);
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.caracteristica {
  color: #FFFFFF;
  background-color: #188bbf82;
  border-radius: 15px;
  border: 0px solid transparent;
  display: inline-block;
  /* Ajuste clave aquí */
  font-size: calc(100vh/60);
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}


.inputEditable {
  width: 100%;
  border-radius: calc(100vh/60);
  height: 100%;
  padding: calc(100vh/60);
  box-sizing: border-box;
  border: 0px;
}

.titleBigCard {
  font-size: calc(100vh/35);
  font-weight: 300;
  letter-spacing: 2;
  color: #3b3b3b;
  text-align: center;
  margin: 0px;
  margin-top: 0px;
}


.btnNextBigCard {
  background-color: #240f5e;
  border-radius: calc(100vh/60);
  border: 0px solid transparent;
  width: calc(100vh/7);
  padding: calc(100vh/70);
  font-size: calc(100vh/65);
  cursor: pointer;
  color: #ffffff;
  align-self: flex-end;
  margin-right: calc(100vh/50);
  margin-left: calc(100vh/50);
  margin-top: calc(100vh/50);
  margin-bottom: calc(100vh/50);

  &:hover {
    background-color: #240f5eda;
  }


}



.list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  padding: calc(100vh/150);
  background-color: $color-terciario-hover;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #bac7e5;
  }
}

.list-active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  padding: calc(100vh/150);
  background-color: rgba(255, 0, 0, 0.212);
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/66);
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #a17b9c;
  }
}

.divTextAgents {
  color: #575757;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: calc(100vh/90);
  margin-bottom: calc(100vh/60);

}









.buttonContainerStart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.titleStart {
  font-size: calc(100vh/55);
  font-weight: 400;
  letter-spacing: 1px;
  color: #000000;
  text-align: center;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: calc(100vh/50);
}





.AddAllItems {
  background-color: $color-principal;
  align-self: center;
  margin: auto;
  margin-top: calc(100vh/100);
  margin-bottom: calc(100vh/100);
  text-align: center;
  color: #FFFFFF;
  font-size: calc(100vh/70);
  border: 0px;
  padding: calc(100vh/120);
  border-radius: calc(100vh/90);
  width: calc(100vh/4);
  cursor: pointer;

  &:hover {
    background-color: $color-principal-hover;
  }
}

.starndardButton {
  background-color: $color-secundario-hover;
  align-self: center;
  margin: auto;
  margin: calc(100vh/60);
  text-align: center;
  color: #FFFFFF;
  font-size: calc(100vh/60);
  border: 0px;
  padding: calc(100vh/120);
  border-radius: calc(100vh/90);
  width: calc(100vh/5);
  cursor: pointer;

  &:hover {
    background-color: $color-quinto;
  }
}

.standardButton {
  background-color: $color-quinto;
  align-self: center;
  margin: auto;
  margin: calc(100vh/60);
  text-align: center;
  color: #FFFFFF;
  font-size: calc(100vh/60);
  border: 0px;
  padding: calc(100vh/120);
  border-radius: calc(100vh/90);
  width: calc(100vh/5);
  cursor: pointer;

  &:hover {
    background-color: $color-terciario;
  }
}

.btnAI {
  background-color: $color-secundario-hover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(100vh/40);
  margin-bottom: calc(100vh/90);
  color: #FFFFFF;
  font-size: calc(100vh/55);
  border: 0px;
  padding: 0 calc(100vh/120);
  border-radius: calc(100vh/50);
  width: calc(100vh/4);
  font-weight: 100;
  height: calc(100vh/30);
  cursor: pointer;

  &:hover {
    background-color: $color-quinto;
  }

  &:disabled {
    background-color: #c4c4c4;
    color: #929294;
  }
}


.okButton {
  background-color: $color-secundario-hover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(100vh/40);
  margin-bottom: calc(100vh/90);
  color: #FFFFFF;
  font-size: calc(100vh/55);
  border: 0px;
  padding: 0 calc(100vh/120);
  border-radius: calc(100vh/50);
  width: calc(100vh/4);
  font-weight: 100;
  height: calc(100vh/30);
  cursor: pointer;

  &:hover {
    background-color: $color-quinto;
  }
}

.okButtonBig {
  background-color: $color-quinto;
  align-self: center;
  margin: auto;
  margin: calc(100vh/90);
  text-align: center;
  color: #FFFFFF;
  font-size: calc(100vh/60);
  border: 0px;
  padding: calc(100vh/120);
  border-radius: calc(100vh/90);
  width: calc(100vh/4);
  cursor: pointer;

  &:hover {
    background-color: $color-principal-hover;
  }
}

.cancelButton {
  background-color: #41071fc9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(100vh/40);
  margin-bottom: calc(100vh/90);
  color: #FFFFFF;
  font-size: calc(100vh/55);
  border: 0px;
  padding: 0 calc(100vh/120);
  border-radius: calc(100vh/50);
  width: calc(100vh/4);
  font-weight: 100;
  height: calc(100vh/30);
  cursor: pointer;

  &:hover {
    background-color: #9c0e49c9;
  }
}

.AddAgentList {
  background-color: #38b000;
  align-self: center;
  margin: auto;
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  border: 0px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 145px;

  &:hover {
    background-color: #174602;
  }

  @media screen and (max-width: 600px) {
    margin-left: 0px;
    margin-right: 60px;
  }

  font-family: "Poppins",
  sans-serif;
}

.inputList {
  margin-top: 10px;
  width: 100%;
  border: 0px;
  background-color: #e4e4fa;
  border-radius: 4px;
  font-size: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;400;700&display=swap");


.canvas-confetti-btn {
  font-size: 4rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  .tool-tip {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    animation: shake 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    color: #000;
    font-weight: 400;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    color: rgb(100, 100, 100);

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -8px;
      bottom: -5px;
      background-color: #fff;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &:hover {
    .tool-tip {
      animation: none;
      opacity: 0;
    }
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(0, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(0, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}


.competenciasEquipo {
  background-color: #0050c7;
  width: 90%;
  border-radius: 3px;
  overflow-x: scroll;
  max-height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
  border: 2px solid #ded2ce;
  color: #FFFFFF;
  margin-right: 55px;
  border-radius: 13px;
  height: 150px;

  &:hover {
    background-color: #eff1d1;
    transition: all 0.1s ease-in-out;
    transform: translate(0px, -5px);
    cursor: pointer;
  }
}


.BtnReenviar,
.BtnReenviarAgents {
  border-radius: 30px;
  /* Más simple y adecuado para botones */
  border: 0;
  color: #ffffff;
  margin-right: 5px;
  /* Establecido en un valor fijo más manejable */
  cursor: pointer;
  font-size: 0.875rem;
  /* Usando rem en lugar de calc con vh para mejor escalabilidad */
  padding: 10px 20px;
  /* Añadido padding para mejorar la accesibilidad y visibilidad */
  text-align: center;
  /* Asegura que el texto esté centrado en el botón */
  display: inline-flex;
  /* Asegura que los elementos dentro del botón estén alineados correctamente */
  align-items: center;
  /* Centra los elementos verticalmente dentro del botón */
  justify-content: center;
  /* Centra los elementos horizontalmente dentro del botón */
}

.BtnReenviar {
  background-color: #42ba92;

  &:hover {
    background-color: #0d7754;
  }
}

.BtnReenviarAgents {
  background-color: #65229c;

  &:hover {
    background-color: #470550;
  }
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {

  .BtnReenviar,
  .BtnReenviarAgents {
    font-size: 1rem;
    /* Aumenta el tamaño de fuente para dispositivos pequeños */
    padding: 12px 24px;
    /* Aumenta el padding para una mejor accesibilidad */
    margin-right: 8px;
    /* Ajusta el margen para evitar la superposición */
  }
}



.threeColumns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  gap: calc(100vh/30);

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.columnsDiv {
  background-color: rgb(237 239 246);
  height: calc(100vh/2);
  overflow: scroll;
  padding: 0.6rem;
  width: 33%;
  margin: auto;
  border-radius: calc(100vh/90);
  box-sizing: border-box;
  box-shadow: $container-shadow;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
}

.titleColumns {
  font-size: calc(100vh/40);
  color: #5a5959;
  font-weight: 700;
  margin-bottom: calc(100vh/100);
}

.liAgent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  background-color: $color-secundario-hover;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $color-secundario;
  }
}

.liAgentActive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  margin-left: calc(100vh/50);
  background-color: $color-secundario;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.liArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  background-color: $color-quinto;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $color-terciario-hover2;
  }
}

.liAreaActive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  margin-left: calc(100vh/50);
  background-color: $color-terciario-hover2;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.liAgent2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  background-color: $color-principal-hover;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $color-principal;
  }
}

.liAgent2Active {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  margin-left: calc(100vh/50);
  background-color: $color-principal;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.liAgent3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(100vh/140);
  color: white;
  padding: calc(100vh/150);
  background-color: #9e09c4;
  text-overflow: ellipsis;
  border-radius: calc(100vh/90);
  font-size: calc(100vh/70);
  text-align: left;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $color-terciario-hover2;
  }
}



.businessPartners {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh/2.8);
  overflow: scroll;
  background-color: #4d07ff0f;
  border-radius: calc(100vh/70);
  padding: auto;
}

.businessPartnersCompetencias {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh/100);
  overflow: scroll;
  background-color: #d9e3f3;
  border-radius: calc(100vh/50);
  padding: auto;
}

.Goals {
  background-color: $color-quinto;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 0.4rem;
  font-size: 20px;
}

.div-goals {
  background-color: rgb(253, 247, 247);
  padding: 0.1rem;
  width: 30%;
  border-radius: 10px;
  margin: auto;

  &:hover {
    background-color: #d4d6df;
    cursor: pointer;
  }
}

.inputBusqueda2 {
  font-size: calc(100vh/70);
  border-radius: calc(100vh/90);
  border: 1px solid #c8baf0;
  padding: calc(100vh/97);
  background-color: #fbfeff;
  width: calc(100vh/5);
  text-align: center;
  margin-top: calc(100vh/122);
}

.divPasswordShow {
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
}

.divPassword {
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
}

@keyframes vibrar {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(2px, 2px);
  }

  50% {
    transform: translate(0);
  }

  75% {
    transform: translate(-2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

.alertMessageRegister {
  background-color: $color-secundario;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  color: white;
  animation: vibrar 0.1s linear 10;
}

.alertMessageRegisterRed {
  background-color: #520707;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
  color: white;
}


.customButton2 {
  background-color: #1b134f;
  border: 0px solid transparent;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $color-secundario;
    box-shadow: $container-shadow;
    transform: translate(0px, -5px);
    border: 1px solid black;
  }

}

.contenedorTickets {
  display: flex;
  width: 90%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  padding: 0px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 96%;
    padding: 0px;
    height: 100%;
  }

  h2 {
    margin-top: 2px;
    margin-bottom: 2px;
    color: $color-cuaternario;
  }
}

@keyframes desplazamientoAbajo {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}


.columnaKanban {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  /* Puedes ajustar la duración de la animación */
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  position: relative;
  flex: 1 1 calc(30% - 120px);
  background-color: #ffffff;
  padding: calc(100vh/100) calc(100vh/100) 0 calc(100vh/100);
  margin: calc(100vh/38);
  height: 100%;
  margin-top: calc(100vh/70);
  border-radius: calc(100vh/40);
  border: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(107, 107, 107, 0.16) 0px 3px 6px;
  z-index: 1;



  .titleKanbanNuevo {
    font-size: calc(100vh/40);
    background: linear-gradient(105.09deg, #4E84F7 -29.77%, #122556 151.06%, #122556 151.06%);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/55);
    font-size: calc(100vh/37);
    font-weight: 300;
    text-align: center;
    border-radius: 15px 15px 40px 40px;
  }

  .titleKanbanProceso {
    font-size: calc(100vh/40);
    background: linear-gradient(105.28deg, #FBC845 -29.95%, #FF7A00 89.61%);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/55);
    font-size: calc(100vh/37);
    font-weight: 300;
    text-align: center;
    border-radius: 15px 15px 40px 40px;
  }

  .titleKanbanTerminado {
    font-size: calc(100vh/40);
    background: linear-gradient(105.09deg, rgba(131, 214, 26, 0.51) -29.77%, #09390E 151.06%);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/55);
    font-size: calc(100vh/37);
    font-weight: 300;
    text-align: center;
    border-radius: 15px 15px 40px 40px;
  }

  .titleKanbanExtra {
    font-size: calc(100vh/40);
    background: linear-gradient(105.09deg, rgba(228, 213, 0, 0.767) -29.77%, #b19708 151.06%);
    color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/55);
    font-size: calc(100vh/37);
    font-weight: 400;
    text-align: center;
    border-radius: 10px 10px 40px 40px;
  }

  .ticket {
    flex: 1;
    background-color: #dce5ee;
    margin-bottom: calc(100vh/80);
    padding: calc(100vh/100);
    text-align: center;
    border: 0px solid #d1dafc;
    border-radius: calc(100vh/100);
    margin: calc(100vh/80);
    position: relative;
    cursor: grab;
    z-index: 998;
    /* Agregar un valor alto de z-index */

  }

  @keyframes animaMenu {

    0%,
    50%,
    100% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(calc(100vh/80));
    }

    100% {
      transform: translatex(calc(100vh/100));
    }
  }

  .ticket:hover {
    cursor: pointer;
    background-color: #ece3f7;
    animation: animaMenu 0.3s forwards;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  .inputIconContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vh/5);
    border-radius: calc(100vh/120);
    border: 1px solid #D9D9D9;
    background-color: #D9D9D9;
    margin-top: calc(100vh/12);
    padding: calc(100vh/200);

    &:hover {
      background-color: #ece8e8;
    }
  }

  .iconWrapperBlue {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh/30);
    /* Asegúrate de que el ancho del iconWrapper coincida con el padding-left del inputBusqueda */
    height: 100%;
    background-color: #4E84F7;
    /* Cambia este valor para ajustar el color de fondo del ícono */
    border-top-left-radius: calc(100vh/120);
    border-bottom-left-radius: calc(100vh/120);
    color: #fbfeff;
    font-size: calc(100vh/50);
  }

  .iconWrapperOrange {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh/30);
    /* Asegúrate de que el ancho del iconWrapper coincida con el padding-left del inputBusqueda */
    height: 100%;
    background-color: #FF7A00;
    /* Cambia este valor para ajustar el color de fondo del ícono */
    border-top-left-radius: calc(100vh/120);
    border-bottom-left-radius: calc(100vh/120);
    color: #fbfeff;
    font-size: calc(100vh/50);
  }

  .iconWrapperGreen {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh/30);
    /* Asegúrate de que el ancho del iconWrapper coincida con el padding-left del inputBusqueda */
    height: 100%;
    background-color: #8ECC3E;
    /* Cambia este valor para ajustar el color de fondo del ícono */
    border-top-left-radius: calc(100vh/120);
    border-bottom-left-radius: calc(100vh/120);
    color: #fbfeff;
    font-size: calc(100vh/50);
  }

  .iconWrapperYellow {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh/30);
    /* Asegúrate de que el ancho del iconWrapper coincida con el padding-left del inputBusqueda */
    height: 100%;
    background-color: #998f03;
    /* Cambia este valor para ajustar el color de fondo del ícono */
    border-top-left-radius: calc(100vh/120);
    border-bottom-left-radius: calc(100vh/120);
    color: #fbfeff;
    font-size: calc(100vh/50);
  }

  .inputIcon {
    color: #c8baf0;
    /* Cambia este valor para ajustar el color del ícono */
  }

  .inputBusqueda {
    font-size: calc(100vh/70);
    border: none;
    padding-left: calc(100vh/40);
    /* Asegúrate de que el padding-left del inputBusqueda coincida con el ancho del iconWrapper */
    padding-right: calc(100vh/97);
    background-color: transparent;
    width: 100%;
    text-align: center;
    margin: 0;
 
  }

  .inputBusqueda:focus {
    box-shadow: none;
    /* Añade esta línea para eliminar el borde del input cuando está enfocado */
    border: 0px;
    outline: none;

  }



  .ticket:last-child {
    margin-bottom: 0;
  }

  .estructuraTicket {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh/13);

  }

  .vencimientoTicket {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #f2f5fa;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencidoTicket {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #f8e1e1;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencimientoTicketProceso {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #FFEFE0;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencidoTicketProceso {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #ffe0e0;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencimientoTicketTerminado {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #E8F5D8;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencidoTicketTerminado {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #ffebeb;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencimientoTicketExtra {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #f5f5d8;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencidoTicketExtra {
    width: calc(100vh/9);
    height: calc(100vh/13);
    border-radius: calc(100vh/100);
    margin-right: calc(100vh/100);
    background-color: #ffebeb;
    padding: 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceTitulo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #3464CC;
    color: white;
    font-size: calc(100vh/90);
    font-weight: 600;
    text-align: center;
    border-radius: calc(100vh/180) calc(100vh/180) 0 0;
    max-height: calc(100vh/47);
    line-height: calc(100vh/47); 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceTituloProceso {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #FF7A00;
    color: white;
    font-size: calc(100vh/90);
    font-weight: 600;
    text-align: center;
    border-radius: calc(100vh/180) calc(100vh/180) 0 0;
    max-height: calc(100vh/47);
    line-height: calc(100vh/47); 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceTituloTerminado {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #8ECC3E;
    color: white;
    font-size: calc(100vh/90);
    font-weight: 600;
    text-align: center;
    border-radius: calc(100vh/180) calc(100vh/180) 0 0;
    max-height: calc(100vh/47);
    line-height: calc(100vh/47); 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceTituloExtra {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #998f03;
    color: white;
    font-size: calc(100vh/90);
    font-weight: 600;
    text-align: center;
    border-radius: calc(100vh/180) calc(100vh/180) 0 0;
    max-height: calc(100vh/47);
    line-height: calc(100vh/47); 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .vencidoTituloTerminado {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #78ac34;
    color: white;
    font-size: calc(100vh/90);
    font-weight: 600;
    text-align: center;
    border-radius: calc(100vh/180) calc(100vh/180) 0 0;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceFecha {
    position: absolute;
    top: 56%;
    left: 50%;
    padding: 0px;
    color: #3464CC;
    font-size: calc(100vh/40);
    font-weight: 600;
    text-align: center;
    transform: translate(-50%, -50%);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceFechaProceso {
    position: absolute;
    top: 56%;
    left: 50%;
    padding: 0px;
    color: #FF7A00;
    font-size: calc(100vh/40);
    font-weight: 600;
    text-align: center;
    transform: translate(-50%, -50%);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }



  .venceFechaTerminado {
    position: absolute;
    top: 56%;
    left: 50%;
    padding: 0px;
    color: #8ECC3E;
    font-size: calc(100vh/40);
    font-weight: 600;
    text-align: center;
    transform: translate(-50%, -50%);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceFechaExtra {
    position: absolute;
    top: 56%;
    left: 50%;
    padding: 0px;
    color: #998f03;
    font-size: calc(100vh/40);
    font-weight: 600;
    text-align: center;
    transform: translate(-50%, -50%);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceSubtitulo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    color: #3464CC;
    font-size: calc(100vh/70);
    font-weight: 600;
    text-align: center;
    border: 0px;
    border-radius: 0 0 calc(100vh/180) calc(100vh/180);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceSubtituloProceso {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    color: #FF7A00;
    font-size: calc(100vh/70);
    font-weight: 600;
    text-align: center;
    border: 0px;
    border-radius: 0 0 calc(100vh/180) calc(100vh/180);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceSubtituloTerminado {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    color: #8ECC3E;
    font-size: calc(100vh/70);
    font-weight: 600;
    text-align: center;
    border: 0px;
    border-radius: 0 0 calc(100vh/180) calc(100vh/180);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .venceSubtituloExtra {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    color: #998f03;
    font-size: calc(100vh/70);
    font-weight: 600;
    text-align: center;
    border: 0px;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
    border-radius: 0 0 calc(100vh/180) calc(100vh/180);
  }

  .infoTicket {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    flex-direction: column;
    border-radius: calc(100vh/100);
    width: 100%;
    height: calc(100vh/13);
    padding: 0px;
    text-overflow: hidden;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .infoTicketProceso {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    flex-direction: column;
    border-radius: calc(100vh/100);
    width: 100%;
    height: calc(100vh/13);
    padding: 0px;
    text-overflow: hidden;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .infoTicketTerminado {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    flex-direction: column;
    border-radius: calc(100vh/100);
    width: 100%;
    height: calc(100vh/13);
    padding: 0px;
    text-overflow: hidden;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .rowTicket {
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh/13);
    margin: 0px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-overflow: hidden;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .columnTicket {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 0px;
    text-overflow: hidden;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */

  }

  .ticketElement {
    font-size: calc(100vh/80);
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: calc(100vh/100);
    text-overflow: hidden;
    max-width: 70px;
    overflow: hidden;
    font-weight: 600;
    color: #3464CC;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */

  }

  .ticketElementProceso {
    font-size: calc(100vh/80);
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: calc(100vh/100);
    text-overflow: hidden;
    max-width: 70px;
    overflow: hidden;
    font-weight: 600;
    color: #FF7A00;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .ticketElementTerminado {
    font-size: calc(100vh/80);
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: calc(100vh/100);
    text-overflow: hidden;
    max-width: 70px;
    overflow: hidden;
    font-weight: 600;
    color: #8ECC3E;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }



  .ticketElementExtra {
    font-size: calc(100vh/80);
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: calc(100vh/100);
    text-overflow: hidden;
    max-width: 70px;
    overflow: hidden;
    font-weight: 600;
    color: #998f03;
    white-space: nowrap;
    /* Evita que el texto se divida en varias líneas */
  }


  .tituloTicketNuevo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #3464CC;
    color: white;
    font-size: calc(100vh/70);
    text-align: center;
    border-radius: calc(100vh/96) calc(100vh/96) 0 0;
    max-height: calc(100vh/47);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .tituloTicketProceso {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #FF7A00;
    color: white;
    font-size: calc(100vh/70);
    text-align: center;
    border-radius: calc(100vh/96) calc(100vh/96) 0 0;
    max-height: calc(100vh/47);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */

  }

  .tituloTicketTerminado {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #8ECC3E;
    color: white;
    font-size: calc(100vh/70);
    text-align: center;
    border-radius: calc(100vh/96) calc(100vh/96) 0 0;
    max-height: calc(100vh/47);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  .tituloTicketExtra {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(100vh/400);
    background-color: #998f03;
    color: white;
    font-size: calc(100vh/70);
    text-align: center;
    border-radius: calc(100vh/96) calc(100vh/96) 0 0;
    max-height: calc(100vh/47);
    user-select: none;
    -webkit-user-select: none; /* Para navegadores WebKit */
    -moz-user-select: none; /* Para Firefox */
    -ms-user-select: none; /* Para IE10+ */
  }

  @keyframes up {

    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(50);
    }

    40% {
      transform: translateY(-calc(100vh/150)) scale(0.9);
    }

    60% {
      transform: translateY(-calc(100vh/100)) scale(0.9);
    }

    70% {
      transform: translateY(calc(100vh/120)) scale(0.9);
    }

    100% {
      transform: translateY(0px) scale(1);
    }
  }


  .dropdown-menu-nuevo {
    animation-name: up;
    animation-duration: 0.1s;
    /* Puedes ajustar la duración de la animación */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -50%;
    right: 0;
    background-color: rgb(8, 10, 78);
    border: 0px solid #d1dafc;
    border-radius: calc(100vh/40) calc(100vh/40) 0 0;
    color: #FFFFFF;
    font-size: calc(100vh/60);
    padding: calc(100vh/160);
    margin-top: 10px;
  }

  .dropdown-menu-proceso {
    animation-name: up;
    animation-duration: 0.1s;
    /* Puedes ajustar la duración de la animación */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -50%;
    /* Agrega esta línea */
    right: 0;
    /* Agrega esta línea */
    background-color: rgb(182, 67, 0);
    border: 0px solid #d1dafc;
    border-radius: calc(100vh/40) calc(100vh/40) 0 0;
    color: #FFFFFF;
    font-size: calc(100vh/60);
    padding: calc(100vh/160);
    margin-top: 10px;
    /* Añade otros estilos si lo deseas */
  }

  .dropdown-menu-terminado {
    animation-name: up;
    animation-duration: 0.1s;
    /* Puedes ajustar la duración de la animación */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -50%;
    /* Agrega esta línea */
    right: 0;
    /* Agrega esta línea */
    background-color: rgb(8, 78, 17);
    border: 0px solid #d1dafc;
    border-radius: calc(100vh/40) calc(100vh/40) 0 0;
    color: #FFFFFF;
    font-size: calc(100vh/60);
    padding: calc(100vh/160);
    margin-top: 10px;
  }

  .dropdown-menu-extra-board {
    animation-name: up;
    animation-duration: 0.1s;
    /* Puedes ajustar la duración de la animación */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -50%;
    /* Agrega esta línea */
    right: 0;
    /* Agrega esta línea */
    background-color: rgb(93, 112, 7);
    border: 0px solid #d1dafc;
    border-radius: calc(100vh/40) calc(100vh/40) 0 0;
    color: #FFFFFF;
    font-size: calc(100vh/60);
    padding: calc(100vh/160);
    margin-top: 10px;
  }

  .dropdown-item {
    display: block;
    padding: calc(100vh/200) calc(100vh/100);
    text-decoration: none;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #2c9676;
    color: white;
    border-radius: 50%;
    width: 50%;
    height: 50%;
  }

  @media (max-width: 768px) {
    .elemento {
      flex-basis: calc(50% - 20px);
    }
  }

  @media (max-width: 480px) {
    .elemento {
      flex-basis: calc(100% - 20px);
    }
  }
}

.ticketLbl {
  padding: calc(100vh/300);
  background-color: #ffffff;
  color: #575757;
  font-weight: 400;
  margin: calc(100vh/110);
  border-bottom: calc(100vh/700) solid #59c2c277;
  text-align: center;
  font-size: calc(100vh/75);

  &:hover {
    background-color: #59c2c24b;
    border-radius: calc(100vh/90);
    cursor: pointer;
  }
}

.modal-overlay {
  z-index: 3331;
  /* Asegúrate de que este valor sea alto */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 19;
  background-color: rgba(49, 66, 160, 0.26);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  z-index: 22222;

  animation: inflate 0.08s ease-out;
  z-index: 9999;
  background-color: rgb(255, 255, 255);
  border-radius: 2rem;
  /* Ajuste relativo */
  padding: 1.25rem;
  /* Ajuste relativo */
  border: 1px solid #d1dafc;
  width: 80vw;
  /* Ajuste relativo */
  max-width: 90vh;
  /* Ajuste relativo */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.titleModal {
  z-index: 9999;

  text-transform: uppercase;
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  /* Ajuste relativo */
  background-color: #3464CC;
  color: white;
  font-size: 1.3rem;
  /* Ajuste relativo */
  text-align: center;
  border-radius: 2rem 2rem 0 0;
  /* Ajuste relativo */
  font-weight: 400;
  letter-spacing: 2;
}

.descriptionModal {
  z-index: 9999;

  width: 100%;
  height: auto;
  background-color: #ffffff;
  color: #575757;
  border-bottom: #F8F8F8 2px solid;
  border-radius: 1rem;
  /* Ajuste relativo */
  font-size: 1.2rem;
  /* Ajuste relativo */
  font-family: cursive;
  padding: 0.5rem;
  /* Ajuste relativo */
  margin-top: 2rem;
  /* Ajuste relativo */
  margin-bottom: 1rem;
  text-align: left;
}

.btnCloseModal {
  background-color: #240f5e;
  font-weight: 260;
  color: white;
  border: 0;
  border-radius: 1rem;
  /* Ajuste relativo */
  cursor: pointer;
  padding: 0.6rem;
  /* Ajuste relativo */
  font-size: 1rem;
  /* Ajuste relativo */
  margin: 1rem;
  /* Ajuste relativo */
  width: 9rem;
  /* Ajuste relativo */
}

/* Media queries for different screen sizes to ensure responsiveness */
@media (max-width: 768px) {
  .modal-content {
    padding: 1rem;
    width: 80vw;
    max-width: 70vh;
  }

  .titleModal {
    font-size: 1rem;
    padding: 0.4rem;
    border-radius: 1.8rem 1.8rem 0 0;
  }

  .descriptionModal {
    font-size: 1rem;
    padding: 0.4rem;
    margin-top: 2.5rem;
    border-radius: 0.8rem;
  }

  .btnCloseModal {
    padding: 0.8rem;
    font-size: 0.9rem;
    margin: 0.8rem;
    width: 7rem;
  }
}

@media (max-width: 480px) {
  .modal-content {
    padding: 0.8rem;
    width: 70vw;
    max-width: 70vh;
  }

  .titleModal {
    font-size: 1rem;
    padding: 0.3rem;
    border-radius: 1.6rem 1.6rem 0 0;
  }

  .descriptionModal {
    font-size: 1.2rem;
    padding: 0.3rem;
    margin-top: 2rem;
    border-radius: 0.6rem;
  }

  .btnCloseModal {
    padding: 0.6rem;
    font-size: 0.8rem;
    margin: 0.6rem;
    width: 7rem;
  }
}


.btnCloseModalCancel {
  background-color: #2f8d88;
  font-weight: 560;
  color: white;
  border: 0px solid #5e0f3d;
  border-radius: calc(100vh/90);
  cursor: pointer;
  padding: calc(100vh/70);
  font-size: calc(100vh/70);
  margin: calc(100vh/70);
  width: calc(100vh/10);
}

.fichaTicketModal {
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  color: #000000;
  border-radius: calc(100vh/96);
  font-size: 0.9rem;
  font-family: cursive;
  padding: calc(100vh/200);
  text-align: left;
  max-height: 30vh;
  overflow: auto;

}


.custom-page-number {
  display: inline-block;
  padding: calc(100vh/180) calc(100vh/90);
  margin-right: calc(100vh/200);
  border: calc(100vh/600) solid #ccc;
  border-radius: calc(100vh/90);
  background-color: #f9f9f9;
  text-decoration: none;
  font-weight: 500;
  font-size: calc(100vh/70);
  color: #3230a7;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-page-number:hover {
  background-color: #240f5e;
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-page-number.active {
  background-color: #bec4d0;
  color: #fff;
  transform: translateY(0);
}


.custom-previous-button,
.custom-next-button {
  display: inline-block;
  padding: calc(100vh/180) calc(100vh/90);
  margin-right: 10px;
  border: 0px solid #ccc;
  font-size: calc(100vh/40);
  border-radius: calc(100vh/90);
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-previous-button:hover,
.custom-next-button:hover {
  background-color: #ffffff;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
  overflow: hidden;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px;
}

.slider {
  width: 200px;
}

.imageUp {
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  padding: calc(100vh/60);
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  background-color: #e9e9e9;
  border-radius: calc(100vh/70);
  cursor: pointer;

  &:hover {
    background-color: #c6e0ec;
  }
}

.imgRegister {
  width: calc(100vh/7);
  height: calc(100vh/7);
  justify-content: center;
  margin: auto;
  align-items: center;
  text-align: center;
  object-fit: cover;
  border-radius: calc(100vh/4);
}

.logo {
  width: calc(100vh/6);
  padding: calc(100vh/50);
}

.titleLogin {
  font-size: calc(100vh/40);
  font-weight: 400;
  letter-spacing: 1px;
  color: #353535;
  margin-bottom: calc(100vh/100);
  margin-top: calc(100vh/300);
  text-align: center;
}

.centerImage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.containerCreateTicket {
  animation-name: desplazamientoAbajo;
  animation-duration: 0.3s;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh / 1.3);
  /* Agrega la propiedad max-height */
  overflow-y: auto;
  align-items: center;
  border-radius: calc(100vh / 50);
  margin: auto;
  padding: calc(100vh / 100);
  width: calc(100vh / 1);
  background-color: rgb(255, 255, 255);
  margin-top: calc(100vh / 30);
  transition: all 0.3s ease-in-out;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-top: calc(100vh / 10);
    box-sizing: border-box;
  }
}

.containerFicha {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(107, 107, 107, 0.16) 0px 3px 6px,
    rgba(122, 122, 122, 0.23) 0px 3px 6px;
  background-color: #3e3568;
  border-radius: calc(100vh / 40);
  padding: calc(100vh / 40);
  margin: auto;
  margin-top: calc(100vh/30);
  margin-bottom: calc(100vh/30);

  color: white;
  box-sizing: border-box;
  position: relative;
}

.containerFicha2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(107, 107, 107, 0.16) 0px 3px 6px,
    rgba(122, 122, 122, 0.23) 0px 3px 6px;
  background-color: #13525a;
  border-radius: calc(100vh / 40);
  padding: calc(100vh / 40);
  margin: auto;
  margin-top: calc(100vh/30);
  margin-bottom: calc(100vh/30);

  color: white;
  box-sizing: border-box;
  position: relative;
}

.row-cols-md-2 {

  /* ... */
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: auto;
  }
}

.empresaContainer {
  position: relative;
  padding: 0;
  margin: 0;
}

.usuarioContainer {
  position: relative;
  padding: 0;
  margin: 0;
}

.dropdownButton {
  animation: inflate 0.2s forwards;
  position: absolute;
  top: 70%;
  left: -8%;
  width: auto;
  height: auto;
  background-color: #f9f9f9;
  border: 1px solid #f1f1f1;
  border-radius: calc(100vh/60);
  padding: 12px;
  margin-top: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-size: calc(100vh/70);
  text-align: center;
  z-index: 1;
  overflow-y: auto;
}

.autocomplete2 {
  width: calc(100vh/4);
  background-color: #c89acd0a;
  border: 0px solid #21d4ef;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: calc(100vh/80);

}

.pagNumber {
  margin: calc(100vh/100);
  padding: calc(100vh/100);
  color: #300129;
}

.pagSelect {
  padding: calc(100vh/200);
  border: 1px solid #5316482f;
  border-radius: 15px;
}

.buttonPag {
  margin: 2px;
  border: 1px solid #5316482f;
  border-radius: 15px;
  background-color: #5316482f;
  color: #300129;
  cursor: pointer;

  &:hover {
    background-color: $color-terciario;
    color: #fff6fd;
  }
}


/* Estilos para el contenedor de la tabla */
.table-container {
  overflow-x: auto;

}

.btnImgUpload {
  background-color: #1b134f;
  border: 0px solid transparent;
  border-radius: calc(100vh/60);
  padding: calc(100vh/100) calc(100vh/30);
  margin-top: calc(100vh/70);
  font-size: calc(100vh/50);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $color-secundario;
    box-shadow: $container-shadow;
    transform: translate(0px, -5px);
    border: 1px solid black;
  }
}







.table-column-cierres {
  width: 48%;
  text-align: center;
  justify-content: center;
  border: 1px solid #210ba060;
  justify-items: center;
  margin: auto;
  margin-bottom: 20px;
  background-color: #e6fbff;
  border-radius: calc(100vh/60);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.invitaciones {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-left: 20px;
  background-color: #6f4d8c;
  width: auto;
  color: #FFFFFF;
  border-radius: calc(100vh/30);
  height: 100%;
  /* Asegura que la altura se extiende a través de todo el contenedor */
}

.invitaciones:hover {
  background-color: #543d69;
}

.invitacionesAceptadas {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-left: 20px;
  background-color: #1b7566a2;
  width: auto;
  color: #FFFFFF;
  border-radius: calc(100vh/30);
  height: 100%;
  /* Asegura que la altura se extiende a través de todo el contenedor */
}

.invitacionesAceptadas:hover {
  background-color: #073c3b;
}

.buttons-container {
  display: flex;
  padding: 10px;
  margin-left: calc(100vh/20);
}

.button-invitation1 {
  border-radius: 40px;
  background-color: #42ba92;
  width: 30px;
  height: 30px;
  border: 0px;
  color: #ffffff;
  margin-right: 10%;
  cursor: pointer;
}

.button-invitation2 {
  border-radius: 150px;
  background-color: #a82438;
  width: 30px;
  height: 30px;
  padding: auto;
  border: 0px;
  color: #ffffff;
  margin-right: 10%;
  cursor: pointer;

}

.column {
  display: flex;
  flex-direction: column;
}

.starsRating {
  margin-top: 20px;
  padding: 30px;
  font-size: 50px;
}

.statsDiv {
  h1 {
    font-size: calc(100vh/40);
    font-weight: 200;
  }

  h2 {
    font-weight: 300;
    font-size: calc(100vh/40);
  }
}

.imgChat {
  width: calc(100vh/30);
  height: calc(100vh/30);
  border-radius: 50%;
  margin-right: calc(100vh/90);
  margin-left: calc(100vh/90);
  margin-top: calc(100vh/90);
  margin-bottom: calc(100vh/90);
}

.chatContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: calc(100vh/80);
  overflow-y: auto;
  height: 100%;
}

/* Cada mensaje */
.chatContent>div {
  display: flex;
  align-items: flex-start;
  margin-bottom: calc(100vh/200);
}

/* Imagen de perfil */
.chatContent .imgChat {
  max-width: calc(100vh/10);
  max-height: calc(100vh/10);
  margin-right: calc(100vh/90);
  border-radius: 50%;
}

/* Mensaje */
.manesajeUsuario,
.mensajeCliente {
  max-width: 70%;
  padding: calc(100vh/100);
  border-radius: calc(100vh/90);
  position: relative;
  margin-bottom: calc(100vh/700);
}

.manesajeUsuario:before,
.mensajeCliente:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}

.manesajeUsuario {
  align-self: flex-end;
  background-color: #EAF7FF;
  /* Color de fondo para mensajes del usuario */
}

.manesajeUsuario:before {
  left: calc(100vh/90)*-1;
  top: 0;
  border: calc(100vh/90) solid;
  border-color: #EAF7FF transparent transparent transparent;
}

.mensajeCliente {
  background-color: #FFE9E2;
  /* Color de fondo para mensajes del cliente */
}

.mensajeCliente:before {
  left: calc(100vh/90)*-1;
  top: 0;
  border: calc(100vh/90) solid;
  border-color: #FFE9E2 transparent transparent transparent;
}

/* Detalles de mensaje (nombre y hora) */
.hraChat {
  font-size: 0.8em;
  color: gray;
}



/* Contenido del mensaje */
.manesajeUsuario>div,
.mensajeCliente>div {
  margin-top: calc(100vh/200);
}


.prettyTable {
  width: 100%;
  margin-top: calc(100vh/80);
  margin-top: calc(100vh/80);
  border-collapse: collapse;
}

.prettyTable td {
  border: 0 solid #ae9bce;
  padding: calc(100vh/140);
  color: #00071d;
  font-size: calc(100vh/70);

}

.prettyTable .titleColumn {
  background-color: rgb(7, 27, 70);
  color: #fff;
}

.prettyTable tr:nth-child(even) {
  background-color: #f9f8fb;
}

.prettyTable tr:hover {
  background-color: #a879a7;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin-top: calc(100vh/90);
  gap: calc(100vh/70);
}

.attachButton {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #811c61;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: calc(100vh/90);
  line-height: 1.5;
  border-radius: calc(100vh/90);
  width: calc(100vh/6);
}

.attachButton:hover {
  background-color: #038b89;
}

.attachButtonCancel {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #69090e;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: calc(100vh/90);
  line-height: 1.5;
  border-radius: calc(100vh/90);
  width: calc(100vh/6);

  &:hover {
    background-color: #038b89;
  }
}

.attachButtonAdjuntar {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #060a3f;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: calc(100vh/90);
  line-height: 1.5;
  border-radius: calc(100vh/90);
  width: calc(100vh/6);

  &:hover {
    background-color: #038b89;
  }
}

.inputBusqueda10 {
  font-size: calc(100vh/70);
  border: none;
  padding-left: calc(100vh/40);
  /* Asegúrate de que el padding-left del inputBusqueda coincida con el ancho del iconWrapper */
  padding-right: calc(100vh/97);
  background-color: transparent;
  width: 100%;
  text-align: center;
  margin: 0;
  justify-content: center;
}

.inputBusqueda10:focus {
  box-shadow: none;
  /* Añade esta línea para eliminar el borde del input cuando está enfocado */
  border: 0px;
  outline: none;
}

.iconWrapperMorado {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vh/30);
  /* Asegúrate de que el ancho del iconWrapper coincida con el padding-left del inputBusqueda */
  height: 100%;
  background-color: #58165f;
  /* Cambia este valor para ajustar el color de fondo del ícono */
  border-top-left-radius: calc(100vh/120);
  border-bottom-left-radius: calc(100vh/120);
  color: #fbfeff;
  font-size: calc(100vh/50);
}

.inputIconContainer2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vh/5);
  border-radius: calc(100vh/120);
  border: 1px solid #D9D9D9;
  background-color: #D9D9D9;
  margin-top: calc(100vh/100);
  padding: calc(100vh/200);

  &:hover {
    background-color: #ece8e8;
  }
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pieChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Puedes ajustar esto según tus necesidades */
  width: 100%;
  /* Puedes ajustar esto según tus necesidades */
}

.link2 {
  color: #000000;
  text-decoration-line: none;
  font-size: calc(100vh/70);
  margin: auto;
  padding: calc(100vh/200);
  display: block;
  background-color: #ffd6f883;
  border-radius: calc(100vh/90);
  margin: calc(100vh/90);

  &:hover {
    background-color: #89339b;
    color: white;
  }

}

.noTicket {
  font-size: calc(100vh/70);
}


.comment {
  width: calc(100vh/2);
  height: calc(100vh/20);
  border-radius: 10px;
  border-color: #000132;
}

.puntos-container-recompensas {
  width: 80%;
  margin: 50px auto;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
  }
}

.puntos-header-recompensas,
.puntos-canje-recompensas,
.puntos-historial-recompensas {
  padding: 10px;
  border-bottom: 10px solid rgba(36, 0, 78, 0.158);
  background-color: rgba(159, 18, 241, 0.048);
}

.puntos-header-recompensas:last-child {
  border-bottom: none;
}

.puntos-header-recompensas {
  background: linear-gradient(to right, #6E48AA, #00c6ff);
  /* A linear gradient from left to right */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 20px 20px 0 0;
}

.puntos-total-recompensas {
  font-weight: bold;
  font-size: 24px;
}

.puntos-canje-recompensas h3,
.puntos-historial-recompensas h3 {
  margin: 0 0 10px 0;
}

.canje-opcion-recompensas {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px;
  background: linear-gradient(to right, #c0e4e3);
  /* A linear gradient from left to right */
  color: rgb(13, 2, 39);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.canje-opcion-recompensas:hover {
  background: $color-secundario-hover;
}

.puntos-historial-recompensas ul {
  list-style: none;
  padding: 0;
}

.puntos-historial-recompensas li {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #ddd;
}

@media (max-width: 768px) {
  .puntos-container-recompensas {
    width: 95%;
  }
}

.header-gradient {
  background: linear-gradient(to right, #9D50BB, #6E48AA, #00c6ff, #00fffc);
  /* A linear gradient from left to right */
  /* Más propiedades para tu cabecera aquí */
}

.canje-title {
  background-color: #5d00c7;
  color: rgb(255, 255, 255);
  font-size: 29px;
  width: 100%;
  text-align: center;
  border-radius: 20px 20px 5px 5px;
}

.historial-title {
  background-color: #00c6ff;
  color: rgb(255, 255, 255);
  font-size: 29px;
  width: 100%;
  text-align: center;
  border-radius: 20px 20px 5px 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Crea 4 columnas */
  grid-gap: 40px;
  /* Espacio entre los elementos de la cuadrícula */
}

.grid-item {
  /* Estilos para los elementos individuales de la cuadrícula */
  padding: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.forgotPasswordLink {
  color: #020f3a;
  text-align: center;
  margin: auto;
  text-decoration: underline;
  margin-top: calc(100vh/90);
}

.remember-pass {
  color: #000132;
}

.alineador {
  display: flex;
  flex-direction: row-reverse;
  /* Alinea los elementos de derecha a izquierda */
  justify-content: space-around;
  /* Añade espacio alrededor de los elementos */
  align-items: center;
  /* Alinea los elementos verticalmente en el centro */
}

.subMenu {
  position: relative;
  z-index: 3;
  display: block;
  transition: all 100ms ease;
  background: linear-gradient(194deg, rgba(8, 10, 78, 1) 5%, rgba(42, 6, 56, 1) 95%);
  box-shadow: 0px 0px calc(100vh/200) 6px rgba(0, 0, 0, 0.233);
  text-align: center;
  align-items: center;
  color: white;
  padding: 10px;
  background-color: #543d69;
  text-decoration: none;
}



@media (max-width: 768px) {
  .popupContainer {
    border-radius: 30px;
    padding: 0px;
    margin: 0 10px;
    max-width: 96%;
    /* Asegura un margen pequeño en los lados */
  }

  .subContainer {
    padding: 15px;
    font-size: calc(100vh/50);
  }

  .titleBot {
    font-size: calc(100vh/90);
    /* Reduce el tamaño de fuente para pantallas pequeñas */
  }

  .closeBtn {
    right: 20px;
    /* Ajusta la posición para asegurar que es visible */
    top: 20px;
  }

  .addTarea,
  .inputCreateTicket,
  .btnAI {
    font-size: 14px;
    /* Ajusta el tamaño de fuente para inputs y botones */
  }
}

@media (max-width: 768px) {
  .popupContainer {
    border-radius: 30px;
    padding: 0px;
    margin: 0 10px;
    max-width: 96%;
    /* Asegura un margen pequeño en los lados */
  }

  .subContainer {
    padding: 15px;
  }

  .titleBot {
    font-size: 16px;
    /* Reduce el tamaño de fuente para pantallas pequeñas */
  }

  .closeBtn {
    right: 20px;
    /* Ajusta la posición para asegurar que es visible */
    top: 20px;
  }

  .addTarea,
  .inputCreateTicket,
  .btnAI {
    font-size: 14px;
    /* Ajusta el tamaño de fuente para inputs y botones */
  }
}

.subContainer {
  padding: 20px;
}

.closeBtn,
.acceptBtn {
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px;
}

.closeBtn {
  position: absolute;
  right: calc(50% - 410px);
  top: calc(50% - 240px);
}

.acceptBtn {
  display: block;
  margin: auto;
}

.titleBot {
  background: linear-gradient(to right, #6E48AA, #00c6ff);
  /* A linear gradient from left to right */
  border-radius: 30px 30px 0px 0px;
  color: white;
  margin-top: 0px;
  text-align: center;
  padding: 1rem;
  justify-content: center;
  font-size: calc(100vh/50);
  font-weight: 500;
  letter-spacing: 2px;
}

.addTarea {
  background-color: white;
  border-radius: calc(100vh/50);
  font-size: calc(100vh/80);
  padding: calc(100vh/90);
  font-weight: 100;


  &:hover {
    background-color: #dce5ee;
    cursor: pointer;
  }
}

.draggable {
  position: fixed;
  /* Usar fixed para que se pueda mover en toda la pantalla */
  width: 150px;
  /* Ajusta el tamaño según necesites */
  height: 100px;
  /* Ajusta el tamaño según necesites */
  background-color: lightblue;
  /* Solo para hacerlo visible, cambia a tu gusto */
  cursor: grab;
  /* Cambia el cursor para indicar que es arrastrable */
  border: 1px solid #ccc;
  /* Opcional: solo para hacerlo más visible */
}

.InputTimeM {
  padding: 0.4rem;
  font-size: 20px;
  margin: 0.2rem;
  text-align: left;
  position: relative;
  display: block;
  font-size: calc(100vh / 70);
  color: #A07890;
  width: calc(100vh / 4.3);
  height: calc(100vh/60);
  border-radius: calc(100vh/50);
  margin-top: 10px;
  border: 1px solid rgba(247, 0, 255, 0.185);
  background-color: #ffffff28;
  margin: auto;


  .inputTimeM:focus {
    box-shadow: none;
    outline: none;
  }

  .inputTimeM:hover {
    background-color: #6e48aa;
    /* Color de fondo en estado hover */
    color: #ffffff;
    /* Cambia el color del texto en estado hover para mejor contraste */
    border-color: #8a2be2;
    /* Opcional: Cambia el color del borde en estado hover si lo deseas */
  }
}

.ticket-preview {
  position: relative;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  opacity: 0.8;
  //transform: translateX(-90rem);
}

.ticket-preview-content {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.ticket-clone {
  background: #dce5ee;
  color: #3a3a3a;
  /* Asegúrate de que el texto dentro del ticket sea legible sobre el fondo oscuro */
  border: 1px solid rgba(255, 255, 255, 0.6);
  /* Borde sutil para resaltar el contorno */
  padding: 10px 12px;
  opacity: 0.8;
  min-width: 100px;
  border-radius: 8px;
  /* Bordes más redondeados */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 15px 25px rgba(0, 0, 0, 0.15);
  /* Sombras más profundas para un efecto elevado */
  backdrop-filter: blur(4px);
  /* Incrementar el desenfoque de fondo para un efecto visual más fuerte */
  filter: blur(0.5px);
  /* Suavizar ligeramente el contenido del ticket para un efecto de enfoque suave */
  transition: transform 0.3s ease-out, opacity 0.3s ease;
  /* Transiciones suaves para transformaciones */
  z-index: 9999;
  /* Asegura que el ticket se muestre encima de otros elementos */
  border-radius: 10px;
  border: #09390E 1px;
}

.columnaKanban:nth-child(1) {
  z-index: 3;
  /* Tareas Pendientes */
}

.columnaKanban:nth-child(2) {
  z-index: 2;
  /* Tareas En Proceso */
}

.columnaKanban:nth-child(3) {
  z-index: 1;
  /* Tareas Terminadas */
}



@keyframes glowing {
  0% {
    filter: blur(20px);
    opacity: 0.8;
  }

  50% {
    filter: blur(30px);
    opacity: 1;
  }

  100% {
    filter: blur(20px);
    opacity: 0.8;
  }
}

.colorPickerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(100vh/60);
  background-color: #f5f4fd;
  margin: calc(100vh/90);
  border-radius: calc(100vh/50);
  border: 1px solid #d5d6da;

}

.colorPickerContainer h3 {
  margin-right: 8px;
  font-size: calc(100vh/60);
  font-weight: 400;
  letter-spacing: 2;
  color: #333;
}

.colorBox {
  width: calc(100vh/30);
  height: calc(100vh/30);
  border-radius: calc(100vh/120);
  cursor: pointer;
}

.colorPalettePopup2 {
  position: absolute;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: inflate 0.1s ease-in-out;
}

.colorPaletteContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.colorOption {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

@keyframes inflate {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.btnContent {
  display: flex;
  align-items: center;
}

.googleLogo {
  width: 20px;
  /* Tamaño del logo, ajusta según necesites */
  margin-right: 5px;
  /* Espacio entre el logo y el texto */
}

.projectOk {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2;
  color: #00071d;
  margin: auto;
  margin-top: 10px;
  padding: 20px;
  background-color: #eefdd7;
  border-radius: 30px;
}

.planiBot {
  font-size: calc(100vh/40);
}

.redirectingMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.iconElement {
  text-align: left;
  color: #bf2249;
  font-size: 20px;
}

.buttonCalendar {
  background-color: #240f5e;
  font-weight: 560;
  color: white;
  border: 0px solid #240f5e;
  border-radius: calc(100vh/120);
  margin-right: 0px;
  cursor: pointer;
  padding: calc(100vh/120);
  font-size: calc(100vh/70);
  margin-top: 15px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.line {
  flex: 1;
  height: 1px;
  background: #d7d7d7;
}

.text {
  margin: 0 10px;
  color: gray;
}

.forgotPasswordLink {
  margin-bottom: 10px;
}

.registerLink {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  font-size: calc(100vh/80);
}

.privacyPolicy {
  text-align: center;
  font-size: calc(100vh/90);
  margin-top: 0px;
  color: gray;
}

.registerText {
  font-size: calc(100vh/70);
}

.agentPhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.addBoardButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;

}

.addBoardButton {
  padding: 10px 20px;
  font-size: calc(100vh/70);
  background-color: #240f5e;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  z-index: 100000;

}

.addBoardButton:hover {
  background-color: #6b3fe0;
}

.addBoardButtonContainer {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.main-content {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: calc(100vh/40);
  /* Ajusta este valor según sea necesario */
}

.addBoardButtonContainer {
  position: fixed;
  bottom: calc(100vh/10);
  /* Ajusta este valor según sea necesario */
  left: 50%;
  transform: translateX(-50%);
}

.allDayTag {
  font-size: calc(100vh/60);
  margin: auto;
  text-align: center;
  color: #7d7d7d;
  font-weight: 200;
  letter-spacing: 1px;
  margin-top: 10px;
  align-items: center;

}

/* Estilo para el contador de tickets */
/* Estilo mejorado para el contador de tickets */
.ticket-count {
  background-color: #e62b63;
  color: white;
  border-radius: 50%;
  width: 1.9em; /* Tamaño ajustado para una mejor visibilidad */
  height: 1.9em; /* Tamaño ajustado para una mejor visibilidad */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7em; /* Tamaño del texto ajustado para mejor legibilidad */
  position: absolute;
  right: 10px; /* Ajusta la distancia del borde derecho */
  top: 50%;
  transform: translateY(-50%); /* Centra verticalmente el contador */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Añade sombra para un efecto de elevación */
  transition: transform 0.2s, background-color 0.2s; /* Añade transiciones para efectos visuales */
}

.ticket-count:hover {
  background-color: #d22054; /* Color más oscuro al pasar el ratón */
  transform: translateY(-50%) scale(1.1); /* Aumenta ligeramente el tamaño al pasar el ratón */
}


/* Estilo para el contenedor del título del componente */
.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* Necesario para que el ticket-count se posicione relativo a esto */
}

.title-container h2 {
  text-align: center;
  flex: 1;
  /* Permite que el título ocupe todo el espacio disponible */
  margin: 0;
  /* Elimina márgenes adicionales */
  color: #666;

}

.select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(100vh/90)
}


.select-container select {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: calc(100vh/220) calc(100vh/160);
  font-size: calc(100vh/80);
  color: #333;
  background-color: #f0f0f0;
  text-align: center;

  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.select-container select:hover {
  border-color: #999;
}

.select-container select:focus {
  border-color: #666;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.sortTitle {
  font-size: calc(100vh/80);
  color: #666;
  margin-bottom: 0;

}

.ticketElementExtra {
  display: flex;
  align-items: center;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messageCountContainer {
  background-color: rgba(244, 244, 244, 1);
  border-radius: 50%;
  width: calc(100vh/40);
  height: calc(100vh/40);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(100vh/70);
  margin-top: calc(100vh/130);
  /* Ajusta según tus necesidades */
}

.messageCountPositive {
  color: red;
}

.projectTitle {
  font-size: calc(100vh/90);
  margin-bottom: calc(100vh/340);
  text-align: center;
  font-weight: 100;
  letter-spacing: 1.3px;
}

.nameBusiness {
  background-color: #226dcc;
  color: #FFFFFF;
  font-size: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.imgSide {
  width: calc(100vh/9);
  height: calc(100vh/9);
  border-radius: calc(100vh / 2);
  margin: calc(100vh / 70);
  padding: calc(100vh / 300);
  box-sizing: border-box;
  position: relative;
  cursor: help;
  border: 5px solid rgba(255, 255, 255, 0.26);
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.rectangle {
  position: absolute;
  z-index: 3;
  display: block;
  left: calc(-100vh / 6.2);
  width: calc(100vh / 4.7);
  height: calc(100vh - calc(100vh / 11));
  transition: all 100ms ease;
  background-color: rgba(10, 5, 34, 0.80);
  box-shadow: 0px 0px calc(100vh / 200) 6px rgba(0, 0, 0, 0.233);
  text-align: center;
  align-items: center;
  border-top: calc(100vh / 96) solid #226dcc;
  backdrop-filter: blur(5px);

  :hover {
    cursor: pointer;
    background: linear-gradient(90deg, #226dcc 7%, #1a3d9e 94%);
    /*     transition: all 0.2s ease;
    animation: animaMenu 0.3s forwards;
    border-radius: 0px 10px 10px 0px; */
  }

  .hamburger-menu {
    display: none;
    cursor: pointer;
    padding: 5px;
    background: transparent;
    border: none;
    position: relative;

  }

  .hamburger-line {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #ffffff;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;
  }


  @media (max-width: 768px) {
    .hamburger-menu {
      transition: all 0.8s ease-in-out;
      display: block;
      top: 35px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: calc(100vh/18);
    display: flex;
    flex-direction: column;
    left: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
  }


  li {
    list-style: none;
    text-align: right;
    font-size: calc(100vh/70);
    color: #fff;

    @media screen and (max-width: 768px) {
      border: 0px;
      position: relative;
      padding: 0rem;
      width: 300px;
      margin: 0px;
      font-size: 15px;
    }
  }

  .closeSessionMenu {
    list-style: none;
    text-align: right;
    font-size: calc(100vh/60);
    color: #fff;
    position: absolute;
    bottom: calc(100vh/760);
    width: 100%;
    margin: 0px;

    @media screen and (max-width: 768px) {
      bottom: 0;
    }
  }


  .link {
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }



  .link-create {
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .linkSession {
    position: absolute;
    bottom: calc(100vh/28);
    right: 0px;
    left: 0px;
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;
    background-color: #0050c734;

    &:hover {
      background-color: #0050c7;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.rectangle.show {
  width: 100%;
  height: calc(100vh - calc(100vh/5));
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  left: 0px;
  text-align: center;

  .link {
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;
    border: 0px;
  }

  .link-create {
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;
    border: 0px;
  }

  .linkSession {
    color: #fff;
    text-decoration-line: none;
    margin: auto;
    padding: calc(100vh/60);
    display: block;
    background-color: #0050c734;

  }

  li {
    border: 0px;
    width: 90%;
  }
}

.rectangle.active {
  left: 0px;
  transition: all 100ms linear;
  border-top: calc(100vh/125) solid $color-terciario;
  
  li {
    border-bottom: calc(100vh/350) solid #0a203800;
    text-align: center;
    
    a.active {
      color: white; /* Aseguramos que el texto sea blanco */
      text-shadow: 0 0 14px rgba(255, 255, 255, 0.3), 0 0 22px rgba(255, 255, 255, 0.2);
    }
    
    @media screen and (max-width: 768px) {
      border-bottom: 0px solid #0c2d4e00;
    }
  }
}

.graphContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 50px;
  width: 50%;
  height: 50%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
}

.graphContainer:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}


.introContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
}

.introText {
  flex: 1;
  margin-right: 20px;
  text-align: justify;
}

.textIntro {
  font-size: 14px;
  line-height: 1.6;
}

.divTextIntro {
  margin-top: 10px;
}

.introImage {
  flex-shrink: 0;
}

.suggestiveImage {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
}

/* Estilo para los inputs */
.modal-content .rows label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.modal-content .rows input[type="date"],
.modal-content .rows input[type="time"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.modal-content .rows input[type="date"]:focus,
.modal-content .rows input[type="time"]:focus {
  border-color: #007BFF;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
  outline: none;
}

/* Estilo para los botones */
.modal-content .btnCloseModal,
.modal-content .btnSaveModal {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.modal-content .btnSaveModal {
  background-color: #4CAF50;
  color: #ffffff;
}

.modal-content .btnSaveModal:hover {
  background-color: #388E3C;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilo para la fila de inputs y botones */
.modal-content .rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0.2rem;
}

.columnww {
  display: flex;
  flex-direction: row;
}


.errorMessage {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  font-size: 14px;
}

.inputContainer1 {
  position: relative;
  margin: auto;
}

.validationIcon {
  position: absolute;
  top: 50%;
}

.validationIcon.valid {
  color: green;
}

.validationIcon.invalid {
  color: red;
}
.title-container-kanbanextraboard {
  position: relative;
  padding: 0px;
  background-color: #f5f5f5;
  transition: background-color 0.3s;
  margin-bottom: 0px; /* To provide space for icons above */
}

.title-container-kanbanextraboard:hover {
  background-color: #e0e0e0;
}

.title-kanbanextraboard {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
}

.icons-kanbanextraboard {
  display: none;
  position: absolute;
  top: 0px;
  left: 15%;
  transform: translateX(-50%);
  gap: 10px; /* Space between icons */
  z-index: 9999;
}

.title-container-kanbanextraboard:hover .icons-kanbanextraboard {
  display: flex;
}

.button-kanbanextraboard {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  padding: 10px;
  border-radius: 50%;
}

.button-kanbanextraboard:hover {
  transform: scale(1.2);
  background-color: #ddd;
}

.icon-kanbanextraboard {
  color: #888;
  font-size: 1rem; /* Increase icon size for better visibility */
}

.icon-kanbanextraboard:hover {
  color: #000;
}

.input-kanbanextraboard {
  width: 100%;
  padding: 5px;
  font-size: 1em;
  border: none;
  border-bottom: 0px solid #888;
  outline: none;
  transition: border-color 0.3s;
}

.input-kanbanextraboard:focus {
  border-bottom: 0px solid #000;
}

.add-board-btn {
  position: fixed;
  top: 130px;
  right: 30px;
  background-color: rgba(255, 255, 255, 0.7); /* Transparente */
  color: #aabbcc; /* Color del ícono */
  border: 1px solid #aabbcc; /* Borde sutil */
  border-radius: 50%;
  width: 20px; /* Más pequeño */
  height: 33px; /* Más pequeño */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12 px; /* Ajustar tamaño del ícono */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.add-board-btn:hover {
  background-color: rgba(255, 255, 255, 1); /* Un poco más opaco al hacer hover */
  color: #0056b3; /* Cambiar el color del ícono al hacer hover */
  border-color: #0056b3; /* Cambiar el color del borde al hacer hover */
}

.ticket-tooltip {
  position: fixed; /* Mantén fixed para que siempre esté visible */
  z-index: 999949; /* Asegura que esté encima de otros elementos */
  background-color: #0056A3; /* Azul elegante */
  color: white; /* Contraste limpio y profesional */
  padding: 8px 12px; /* Más compacto */
  border-radius: 8px; /* Bordes más redondeados para suavizar el diseño */
  font-size: 14px; /* Tamaño de fuente profesional y legible */
  font-weight: 500; /* Un poco más de peso para destacar */
  width: auto; /* Ajusta dinámicamente al contenido */
  max-width: 250px; /* Permite un diseño más espacioso si es necesario */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra más sutil y elegante */
  pointer-events: none; /* Evita que interfiera con el cursor */
  white-space: nowrap; /* Evita que el texto se divida */
  overflow: hidden; /* Previene desbordamientos */
  text-overflow: ellipsis; /* Añade puntos suspensivos si es necesario */
}

.ticket-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #240f5e transparent transparent transparent;
}

.ticket-tooltip p {
  margin: 5px 0;
  font-size: 0.9em;
}