.trash-can {
    position: fixed;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #8b0000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .trash-can:hover {
    transform: scale(1.2);
    background-color: #b22222;
  }
  
  .trash-can svg {
    color: white;
    font-size: 24px;
  }

  