.link {
  display: flex;
  align-items: center;
  gap: 20px;
}

.linkSession {
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu-text {
  white-space: nowrap;
  margin-left: 5px;
}  