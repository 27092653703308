.form-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  padding: 2rem;
  box-sizing: border-box;
}


.form-actions{
  width: 60%;
  margin: auto;
  padding: auto;
}

.intro-text-container {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.intro-text-container:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.intro-text {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.highlight {
  font-weight: 600;
  color: #6904b1;
}

.example {
  font-style: italic;
  color: #666;
}

.project-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.project-item {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.project-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-icon {
  font-size: 1.5rem;
}

.project-name {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-color-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--project-color);
}

.delete-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-item:hover .delete-button {
  opacity: 1;
}

.project-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #240f5e;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-size: 0.9rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 10;
  width: max-content;
  max-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: tooltipFadeIn 0.3s ease-out;
}

.project-tooltip.active {
  opacity: 1;
  visibility: visible;
}

@keyframes tooltipFadeIn {
  from { opacity: 0; transform: translate(-50%, 10px); }
  to { opacity: 1; transform: translate(-50%, 0); }
}

.tooltip-arrow {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #240f5e;
}

.project-tooltip h4 {
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.project-tooltip p {
  margin: 0;
  font-size: 0.9rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 1rem;
  margin: auto;
  text-align: center;
}



@media (max-width: 768px) {
  .form-step {
    padding: 1rem;
    margin-top: 30px;
  }

  .intro-text-container {
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }


  .project-list-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .form-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .form-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .form-step {
    padding: 0.5rem;
  }

  .intro-text-container {
    padding: 1rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    width: auto;
  }

  .project-list-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .project-item {
    padding: 0.75rem;
  }

  .project-name {
    font-size: 0.9rem;
  }
}