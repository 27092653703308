.notifications-sidebar {
  position: fixed;
  right: -10px;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #fefefef4;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
  padding: 30px;
  transition: right 0.5s ease-in-out, background-color 0.3s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-left: 1px solid #e0e0e0;
  backdrop-filter: blur(2px);
}

.dark-mode .notifications-sidebar {
  background-color: #1e1e1ef4;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-left-color: #444;
}

.notifications-sidebar.open {
  right: 0;
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.notifications-header h2 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin: 0;
  transition: color 0.3s ease;
}

.dark-mode .notifications-header h2 {
  color: #e0e0e0;
}

.close-btn2 {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.close-btn:hover {
  color: #555;
}

.dark-mode .close-btn:hover {
  color: #b0b0b0;
}

.notification-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.dark-mode .notification-item {
  background-color: #2c2c2c;
  border-color: #444;
}

.notification-item.animate {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-item:hover {
  background-color: #f8f8f8;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.dark-mode .notification-item:hover {
  background-color: #3a3a3a;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.notification-content-container {
  display: flex;
  align-items: center;
}

.notification-icon {
  margin-right: 10px;
}

.notification-content {
  flex-grow: 1;
  cursor: pointer;
}

.notification-content .title {
  font-weight: bold;
  font-size: 12px;
  color: #444;
  margin: 0;
  transition: color 0.3s ease;
}

.dark-mode .notification-content .title {
  color: #e0e0e0;
}

.notification-content .message {
  font-size: 10px;
  color: #666;
  margin: 5px 0;
  transition: color 0.3s ease;
}

.dark-mode .notification-content .message {
  color: #b0b0b0;
}

.notification-content .timestamp {
  font-size: 9px;
  color: #aaa;
  transition: color 0.3s ease;
}

.dark-mode .notification-content .timestamp {
  color: #888;
}

.mark-as-read-container {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.mark-as-read-btn {
  background-color: transparent;
  color: #999;
  border: 1px solid #999;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  width: 100%;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .mark-as-read-btn {
  color: #b0b0b0;
  border-color: #666;
}

.mark-as-read-btn:hover {
  color: #555;
  background-color: #f0f0f0;
  border-color: #555;
}

.dark-mode .mark-as-read-btn:hover {
  color: #e0e0e0;
  background-color: #444;
  border-color: #888;
}

.mark-as-read-btn svg {
  margin-right: 5px;
}

.no-notifications {
  text-align: center;
  color: #999;
  font-size: 16px;
  margin-top: 30px;
  transition: color 0.3s ease;
}

.dark-mode .no-notifications {
  color: #b0b0b0;
}

.no-notifications svg {
  margin-right: 10px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .notifications-sidebar {
    width: 100%;
  }
}