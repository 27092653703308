.element {
  border: 1px solid #ddd;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  resize: both;
  overflow: auto;
  transition: all 0.3s ease-in-out;
}

.element:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Estilos para textarea */
textarea {
  width: 100%;
  height: auto;
  border: none;
  resize: none;
  font-size: 16px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: transparent;
  overflow: hidden;
}

/* Estilos para label */
label {
  cursor: pointer;
  display: block;
  padding: 8px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
}

/* Estilos para la paleta de colores */
.colorPalettePopup {
  position: absolute;
  top: -65px;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 300px;
}

.colorOption {
  width: 20px;
  height: 20px;
  margin: 3px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}

.colorOption:hover {
  transform: scale(1.2);
  border-color: #bbb;
}

/* Estilos del contenedor de la paleta de colores */
.colorPaletteContainer {
  display: flex;
  flex-wrap: wrap;
}

/* Estilos para el ícono de bote de pintura */
.paint-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #555;
  transition: color 0.3s;
}

.paint-icon:hover {
  color: #000;
}