@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.7) translateY(-40px);
    filter: blur(10px);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.02) translateY(5px);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
    filter: blur(0px);
  }
}

.popupBackground {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  padding: 20px;
  box-sizing: border-box;
  animation: backgroundFadeIn 0.01s ease-out;
}

.popupContainer {
  background-color: #ffffff;
  border-radius: 12px;
  width: auto;
  min-width: 300px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  transform-origin: center center;
}

.popupHeader {
  background-color: #3464cc;
  padding: 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.popupTitle {
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
  line-height: 1.2;
}

.popupContent {
  padding: 24px;
  color: #333;
  overflow-y: auto;
  flex-grow: 1;
  max-width: 100%;
  box-sizing: border-box;
}

.popupField {
  margin-bottom: 20px;
  animation: slideInField 0.5s ease-out backwards;
}

.form-field:nth-child(1) { animation-delay: 0.1s; }
.form-field:nth-child(2) { animation-delay: 0.2s; }
.form-field:nth-child(3) { animation-delay: 0.3s; }
.form-field:nth-child(4) { animation-delay: 0.4s; }
.form-field:nth-child(5) { animation-delay: 0.5s; }

.popupLabel {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 6px;
  display: block;
  color: #333;
}

.popupInput,
.popupSelect {
  width: 100%;
  padding: 10px 14px;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f7f8fc;
  color: #333;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.popupInput:focus,
.popupSelect:focus {
  outline: none;
  border-color: #3464cc;
  box-shadow: 0 0 0 3px rgba(52, 100, 204, 0.1);
}

.popupActions {
  display: flex;
  justify-content: flex-end;
  padding: 20px 24px;
  background-color: rgba(0, 0, 0, 0.03);
}

.popupButton {
  background-color: #3464cc;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center; 
  margin: 10px;
}

.popupButton:hover {
  background-color: #240f5e;
  transform: translateY(-2px);
}

.popupButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.taskTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.taskTable th,
.taskTable td {
  padding: 10px 14px;
  text-align: left;
  white-space: nowrap;
}

.taskTable th {
  background-color: rgba(0, 0, 0, 0.03);
  font-weight: 600;
}

.taskTable tr:not(:last-child) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.popUpRow {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.popupInputHelperText {
  color: #d02828;
  font-size: 0.8rem;
  margin-top: 4px;
}

.dark-mode .popupBackground {
  background-color: rgba(0, 0, 0, 0.7);
}

.dark-mode .popupContainer {
  background-color: #1e1e1e;
}

.dark-mode .popupHeader {
  background-color: #1a3366;
}

.dark-mode .popupContent,
.dark-mode .popupLabel {
  color: #e0e0e0;
}

.dark-mode .popupInput,
.dark-mode .popupSelect {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .popupButton {
  background-color: #4a7ae0;
}

.dark-mode .popupButton:hover {
  background-color: #3464cc;
}

.dark-mode .taskTable {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .taskTable th {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .taskTable tr:not(:last-child) td {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .popupInputHelperText {
  color: #ff6363;
}

@media (max-width: 768px) {
  .popupBackground {
    padding: 10px;
  }

  .popupContainer {
    width: 100%;
    min-width: 0;
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
    margin: 0 auto;
  }
  
  .popupContent {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .popupInput,
  .popupSelect {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }

  .popupField {
    width: 100%;
    min-width: 0;
    margin-bottom: 15px;
  }

  .popupHeader {
    padding: 15px;
  }

  .popupTitle {
    font-size: 1.2rem;
  }

  .popupActions {
    padding: 15px;
  }

  .popupButton {
    width: 100%;
    margin: 5px 0;
  }

  .form-field {
    width: 100%;
    min-width: 0;
    margin-bottom: 15px;
  }

  .form-field input,
  .form-field select,
  .form-field textarea {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }

  .popupInput,
  .popupSelect {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
  }

  .edit-form-content {
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
  }

  .edit-event-section {
    width: 100%;
    min-width: 0;
    box-sizing: border-box;
  }
}

@media (max-width: 320px) {
  .popupBackground {
    padding: 5px;
  }

  .popupContainer {
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 10px);
  }

  .popupContent {
    padding: 10px;
  }
}

.form-field {
  width: 100%;
  box-sizing: border-box;
}

.form-field input,
.form-field select {
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
}

/* Estilos mejorados para los selects */
.form-field select {
  width: 100%;
  padding: 12px 16px;
  height: 48px; /* Aumentado de 42px a 48px */
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  background-color: #f8faff;
  color: #334155;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  line-height: 1.2;
  
  /* Mejorar el dropdown arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236b89c1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 18px;
  padding-right: 48px;
}

/* Ajustes para las opciones del select */
.form-field select option {
  padding: 12px;
  font-size: 0.95rem;
  background-color: white;
  color: #334155;
  height: 48px; /* Asegurar que las opciones tengan la misma altura */
  line-height: 48px;
}

/* Ajustes específicos para el selector de recurrencia */
.recurrence-selector select {
  width: 100%;
}

/* Ajustes para inputs de tiempo para mantener consistencia */
input[type="time"] {
  width: 100%;
  padding: 12px 16px;
  height: 48px;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  font-size: 0.95rem;
  background-color: #f8faff;
  color: #334155;
  font-weight: 500;
}

/* Estilos hover y focus */
.form-field select:hover,
input[type="time"]:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

.form-field select:focus,
input[type="time"]:focus {
  outline: none;
  border-color: #3464cc;
  box-shadow: 0 0 0 3px rgba(52, 100, 204, 0.1);
  background-color: #fff;
}

/* Modo oscuro */
.dark-mode .form-field select,
.dark-mode input[type="time"] {
  background-color: #1e293b;
  border-color: #334155;
  color: #e2e8f0;
}

.dark-mode .form-field select option {
  background-color: #1e293b;
  color: #e2e8f0;
}

.dark-mode .form-field select:hover,
.dark-mode input[type="time"]:hover {
  background-color: #263449;
  border-color: #475569;
}

.dark-mode .form-field select:focus,
.dark-mode input[type="time"]:focus {
  border-color: #4a7ae0;
  box-shadow: 0 0 0 3px rgba(74, 122, 224, 0.1);
  background-color: #1e293b;
}

/* Responsive */
@media (max-width: 768px) {
  .form-field select,
  input[type="time"] {
    height: 48px; /* Mantener altura consistente en móvil */
    font-size: 16px; /* Prevenir zoom en iOS */
  }
  
  .recurrence-selector select {
    min-width: 0;
  }
}

/* Ajustes para el grupo de campos de tiempo */
.time-fields-container {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
  width: 100%;
}

.time-fields-container .form-field {
  flex: 1;
  min-width: 0;
}

/* Ajustar el layout de columnas */
.edit-form-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Ajustar el espaciado vertical entre elementos */
.form-field {
  margin-bottom: 0; /* Removemos el margin-bottom general */
}

/* Asegurar que el Quill tenga suficiente espacio */
.form-field .quill-container {
  min-height: 150px; /* Aumentar altura mínima para mejor visualización */
  height: calc(100% - 40px); /* Altura dinámica restando el espacio del label */
}

/* Responsive */
@media (max-width: 768px) {
  .edit-form-content {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .form-column {
    gap: 12px;
  }

  .form-field .quill-container {
    min-height: 120px;
  }
}

/* Estilos para el botón de varita mágica */
.magic-button {
  background: transparent;
  border: none;
  padding: 4px;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
}

.magic-button:hover {
  background-color: rgba(52, 100, 204, 0.1);
  color: #3464cc;
}

.magic-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Ajustar el contenedor de la descripción */
.description-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.description-label > div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Spinner para el botón de magia */
.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(52, 100, 204, 0.1);
  border-top-color: #3464cc;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Estilos mejorados para ReactQuill */
.form-field .quill-container {
  min-height: 38px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #f8faff;
  border-radius: 12px;
  border: 1px solid #e6f0ff;
  transition: all 0.2s ease;
}

.form-field .quill-container:focus-within {
  border-color: #3464cc;
  box-shadow: 0 0 0 3px rgba(52, 100, 204, 0.1);
}

/* Estilos para el editor Quill */
.form-field .custom-quill {
  border: none;
}

.form-field .ql-toolbar {
  border: none !important;
  border-bottom: 1px solid #e6f0ff !important;
  background-color: #f8faff;
  border-radius: 12px 12px 0 0;
  padding: 8px 12px;
}

.form-field .ql-container {
  border: none !important;
  background-color: #f8faff;
  border-radius: 0 0 12px 12px;
  font-size: 0.95rem;
  min-height: 38px;
}

.form-field .ql-editor {
  min-height: 38px;
  padding: 8px 12px;
}

.form-field .ql-editor.ql-blank::before {
  font-style: normal;
  color: #94a3b8;
}

/* Modo oscuro para Quill */
.dark-mode .form-field .quill-container {
  background-color: #f6f9ff;
  border-color: #334155;
}

.dark-mode .form-field .ql-toolbar {
  background-color: #1e293b;
  border-bottom-color: #334155 !important;
}

.dark-mode .form-field .ql-container {
  background-color: #1e293b;
  color: #e6f0ff;
}

.dark-mode .form-field .ql-editor.ql-blank::before {
  color: #64748b;
}

.dark-mode .form-field .quill-container:focus-within {
  border-color: #4a7ae0;
  box-shadow: 0 0 0 3px rgba(74, 122, 224, 0.1);
}

/* Estilos para los botones de la barra de herramientas */
.ql-toolbar button {
  padding: 4px !important;
  height: 24px !important;
  width: 24px !important;
}

.ql-toolbar .ql-stroke {
  stroke: #475569 !important;
}

.ql-toolbar .ql-fill {
  fill: #475569 !important;
}

.ql-toolbar button:hover .ql-stroke {
  stroke: #3464cc !important;
}

.ql-toolbar button:hover .ql-fill {
  fill: #3464cc !important;
}

.dark-mode .ql-toolbar .ql-stroke {
  stroke: #94a3b8 !important;
}

.dark-mode .ql-toolbar .ql-fill {
  fill: #94a3b8 !important;
}

.dark-mode .ql-toolbar button:hover .ql-stroke {
  stroke: #4a7ae0 !important;
}

.dark-mode .ql-toolbar button:hover .ql-fill {
  fill: #4a7ae0 !important;
}

/* Estilos para el toggle slider */
.all-day-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  height: 48px;
  background-color: #f8faff;
  border: 1px solid #e6f0ff;
  border-radius: 12px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
}

.all-day-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #334155;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2;
}

.all-day-checkbox {
  opacity: 0;
  position: absolute;
  right: 16px;
  width: 48px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  z-index: 1;
}

.toggle-slider {
  position: relative;
  width: 48px;
  height: 24px;
  background-color: #e2e8f0;
  border-radius: 24px;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.toggle-slider::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.all-day-toggle:hover .toggle-slider {
  background-color: #cbd5e1;
}

.all-day-toggle:hover .toggle-slider::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.all-day-checkbox:checked + .toggle-slider {
  background-color: #3464cc;
}

.all-day-checkbox:checked + .toggle-slider::before {
  transform: translateX(24px);
}

/* Modo oscuro */
.dark-mode .all-day-toggle {
  background-color: #1e293b;
  border-color: #334155;
}

.dark-mode .all-day-label {
  color: #e2e8f0;
}

.dark-mode .toggle-slider {
  background-color: #475569;
}

.dark-mode .all-day-toggle:hover .toggle-slider {
  background-color: #64748b;
}

.dark-mode .toggle-slider::before {
  background-color: #e2e8f0;
}

.dark-mode .all-day-checkbox:checked + .toggle-slider {
  background-color: #4a7ae0;
}

/* Responsive */
@media (max-width: 768px) {
  .all-day-toggle {
    height: 48px;
    padding: 12px 16px;
  }
}

/* Estilos para el título de sección */
.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #334155;
  font-size: 0.95rem;
  font-weight: 600;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6f0ff;
}

.section-title svg {
  color: #3464cc;
}

/* Modo oscuro */
.dark-mode .section-title {
  color: #e2e8f0;
  border-bottom-color: #334155;
}

.dark-mode .section-title svg {
  color: #4a7ae0;
}

.errorMessageResumeTarea {
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  border-radius: 12px;
  padding: 16px 20px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #dc2626;
  font-size: 0.95rem;
  animation: slideUpFade 0.3s ease-out;
}

.errorMessageResumeTarea span {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Modo oscuro */
.dark-mode .errorMessageResumeTarea {
  background-color: #7f1d1d;
  border-color: #991b1b;
  color: #fecaca;
}

.success-message-resumeTarea {
  background: linear-gradient(135deg, #f0f9ff 0%, #e6f6ff 100%);
  color: #0c4a6e;
  font-size: 1rem;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #bae6fd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06),
              inset 0 2px 4px rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin: 20px 0;
  animation: slideUpFade 0.5s ease-out;
  position: relative;
  overflow: hidden;
}

.success-message-resumeTarea::before {
  content: "✨";
  font-size: 1.2rem;
  margin-right: 8px;
}

.spinner-container-resumeTarea {
  position: relative;
  width: 40px;
  height: 40px;
}

.spinner-ring-resumeTarea {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-top-color: #0284c7;
  border-radius: 50%;
  animation: spinnerRing 1s linear infinite;
}

.spinner-dot-resumeTarea {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background: #0284c7;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: spinnerPulse 1s ease-in-out infinite;
}

@keyframes spinnerRing {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spinnerPulse {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes slideUpFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Modo oscuro */
.dark-mode .success-message-resumeTarea {
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  color: #7dd3fc;
  border-color: #0369a1;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
              0 2px 4px -1px rgba(0, 0, 0, 0.1),
              inset 0 2px 4px rgba(255, 255, 255, 0.05);
}

/* Nuevo spinner minimalista para área y prioridad */
.spinner-23f4 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: conic-gradient(from 0deg, #3464cc00, #3464cc);
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  animation: spinner-23f4-rotate 1s infinite linear;
}

.dark-mode .spinner-23f4 {
  background: conic-gradient(from 0deg, #4a7ae000, #4a7ae0);
}

@keyframes spinner-23f4-rotate {
  to {
    transform: rotate(1turn);
  }
}

/* Contenedor para el label y el spinner */
.label-with-spinner-23f4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
}

.label-content-23f4 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #334155;
  font-size: 0.95rem;
  font-weight: 500;
}

.dark-mode .label-content-23f4 {
  color: #e2e8f0;
}

/* Estilos para el input de título con check */
.animate-field {
  position: relative;
  padding-right: 40px !important; /* Espacio para el check */
}

.title-input-container {
  position: relative;
  width: 100%;
}

.title-check {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #10b981;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.title-check.visible {
  opacity: 1;
}

/* Modo oscuro */
.dark-mode .title-check {
  color: #34d399;
}